import {
  Container,
  Navigation,
  UserContainer,
  NotificationContainer,
  NotificationLabel,
  LanguageContainer,
  SelectedLanguageContainer,
  SelectedLanguage,
  LanguageDropdownContainer,
  NotificationDropdownContainer,
  Flag,
  FlagContainer,
  FlagText,
  BellContainer,
  NotificationTitle,
  NotificationDescription,
  NotificationItemContainer,
  HideSidebarContainer,
} from "./styles";

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import {
  setNotificationAsRead,
  setNotifications,
} from "../../redux/modules/notification/actions";

import { MdNotifications } from "react-icons/md";
import brazilFlag from "../../assets/images/brazil-flag.png";
import ukFlag from "../../assets/images/uk-flag.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { changeLanguagePreference } from "../../redux/modules/language/actions";
import { useSidebar } from "../../hooks/sidebar";
import Modal from "../Modal";
import { useIntl } from "react-intl";
import { useLoading } from "../../hooks/loading";
import { authSuccess } from "../../redux/modules/auth/actions";
import { useHistory } from "react-router-dom";

const languages = [
  { code: "pt", flag: brazilFlag, text: "Português" },
  { code: "en", flag: ukFlag, text: "English" },
];

function Topbar() {
  const dispatch = useDispatch();
  const { code } = useSelector((state) => state.language);
  const { token, keepSignedIn } = useSelector((state) => state.auth);
  const { isAccountLoading, handleAccountLoading } = useLoading();
  const history = useHistory();
  const intl = useIntl();
  const { notifications, unread } = useSelector((state) => state.notification);
  const [languageVisible, setLanguageVisible] = useState(false);
  const [activeFlag, setActiveFlag] = useState(ukFlag);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const { sidebarIsVisible, toogleSidebar } = useSidebar();
  const [timerDisabled, setTimerDisabled] = useState(false);

  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState();
  const [accountData, setAccountData] = useState();

  const handleLanguageDropdown = () => {
    if (languageVisible) {
      setLanguageVisible(false);
    } else {
      setLanguageVisible(true);
      setNotificationVisible(false);
    }
  };

  const handleNotificationDropdown = () => {
    if (notificationVisible) {
      setNotificationVisible(false);
    } else {
      setNotificationVisible(true);
      setLanguageVisible(false);
    }
  };

  const handleLanguageChange = (language) => {
    dispatch(
      changeLanguagePreference({
        code: language,
        token: token,
      })
    );
  };

  const formatNotification = (text, type) => {
    const sanitizedType = type.toUpperCase().replaceAll(" ", "_");
    let message = "";

    switch (sanitizedType) {
      case "ADMIN_VIP_DEADLINE":
        message = text.replace(
          "[CLICK_HERE_LINK]",
          `<a href="/admin/guests-report" style="text-decoration: none; color: #002244;">${intl.formatMessage(
            {
              id: "click_here",
            }
          )}</a>`
        );
        break;

      case "ADMIN_CREDENTIALS_ASSOCIATION":
        message = text.replace(
          "[CLICK_HERE_LINK]",
          `<a href="/admin/exhibitors-didnt-create-credential-report" style="text-decoration: none; color: #002244;">${intl.formatMessage(
            { id: "click_here" }
          )}</a>`
        );
        break;

      case "ADMIN_ADVANCE_PURCHASE":
        message = text.replace(
          "[CLICK_HERE_LINK]",
          `<a href="/admin/digital-products-report" style="text-decoration: none; color: #002244;">${intl.formatMessage(
            {
              id: "click_here",
            }
          )}</a>`
        );
        break;

      default:
        message = text;
        break;
    }

    return message;
  };

  const handleOpenNotification = (id, text, read, notificationType) => {
    handleNotificationDropdown();

    if (!read) {
      dispatch(setNotificationAsRead({ id, token }));
    }

    const message = formatNotification(text, notificationType);

    let newNotifications = [];

    notifications.forEach((value) => {
      if (id === value.id) {
        newNotifications.push({ ...value, read: true });
      } else {
        newNotifications.push(value);
      }
    });

    dispatch(setNotifications({ notifications: newNotifications, unread }));

    setSelectedNotification(message);
    setNotificationModalOpen(true);
  };

  useEffect(() => {
    const language = languages.find((language) => language.code === code);
    if (language) {
      setActiveFlag(language.flag);
    }
  }, [code]);

  useEffect(() => {
    if (isAccountLoading && accountData) {
      dispatch(
        authSuccess({
          user: accountData.user,
          token: accountData.token,
          customerCenterToken: accountData.customerCenterToken,
          accountCreated: accountData.accountCreated,
          keepSignedIn,
          serviceProviderLoggingAsAnExhibitor:
            accountData.serviceProviderLoggingAsAnExhibitor,
        })
      );
      handleAccountLoading(false);
      history.push("/dashboard");
    }
  }, [
    accountData,
    dispatch,
    handleAccountLoading,
    history,
    isAccountLoading,
    keepSignedIn,
  ]);

  useEffect(() => {
    if (isAccountLoading) {
      setTimerDisabled(true);
    } else if (timerDisabled) {
      setTimeout(() => setTimerDisabled(false), 5000);
    }
  }, [isAccountLoading, timerDisabled]);

  return (
    <Container>
      <Navigation sidebarIsVisible={sidebarIsVisible}>
        <HideSidebarContainer>
          {sidebarIsVisible ? (
            <BsChevronLeft size={19} onClick={() => toogleSidebar()} />
          ) : (
            <BsChevronRight size={19} onClick={() => toogleSidebar()} />
          )}
        </HideSidebarContainer>
        <UserContainer>
          <NotificationContainer>
            <BellContainer onClick={() => handleNotificationDropdown()}>
              <MdNotifications color="#002244" size={24} />
              {unread > 0 && <NotificationLabel>{unread}</NotificationLabel>}
            </BellContainer>

            {notificationVisible && (
              <NotificationDropdownContainer
                sidebarIsVisible={sidebarIsVisible}
              >
                {notifications?.map((notification, index) => {
                  const { id, title, body, read, notificationType } =
                    notification;

                  return (
                    <NotificationItemContainer
                      key={index}
                      read={read}
                      onClick={() =>
                        handleOpenNotification(id, body, read, notificationType)
                      }
                    >
                      <NotificationTitle>{title}</NotificationTitle>
                      <NotificationDescription>{body}</NotificationDescription>
                    </NotificationItemContainer>
                  );
                })}
              </NotificationDropdownContainer>
            )}
          </NotificationContainer>
          <LanguageContainer>
            <SelectedLanguageContainer>
              <SelectedLanguage
                onClick={() => handleLanguageDropdown()}
                src={activeFlag}
                size={24}
              />
            </SelectedLanguageContainer>
            {languageVisible && (
              <LanguageDropdownContainer sidebarIsVisible={sidebarIsVisible}>
                {languages.map((language, index) => (
                  <FlagContainer
                    key={index}
                    onClick={() => handleLanguageChange(language.code)}
                  >
                    <Flag src={language.flag} />
                    <FlagText>{language.text}</FlagText>
                  </FlagContainer>
                ))}
              </LanguageDropdownContainer>
            )}
          </LanguageContainer>
        </UserContainer>
      </Navigation>
      <Modal
        modalIsOpen={isNotificationModalOpen}
        setIsOpen={setNotificationModalOpen}
      >
        <div dangerouslySetInnerHTML={{ __html: selectedNotification }}></div>
      </Modal>
    </Container>
  );
}

export default Topbar;
