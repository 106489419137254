import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  LinkButton,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Badge from "../../components/Badge";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import { useHistory } from "react-router";
import { authSuccess } from "../../redux/modules/auth/actions";
import { changeApplicationLanguage } from "../../redux/modules/language/actions";

const ImpersonateUser = () => {
  const { keepSignedIn, token, adminToken, user } = useSelector(
    (state) => state.auth
  );
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const history = useHistory();

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [accounts, setAccounts] = useState([]);
  const dispatch = useDispatch();
  const [accountData, setAccountData] = useState();
  const { isAccountLoading, handleLoading, handleAccountLoading } =
    useLoading();

  const [loading, setLoading] = useState(false);
  const loggedInUserId = user?.id;

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    role: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    account: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  useEffect(() => {
    handleLoading(true);
    setRoles([
      {
        label: intl.formatMessage({ id: "exhibitor" }),
        value: "EXHIBITOR",
      },
      {
        label: intl.formatMessage({ id: "sponsor_without_m2" }),
        value: "SPONSOR_WITHOUT_M2",
      },
      {
        label: intl.formatMessage({ id: "digital_only" }),
        value: "DIGITAL_ONLY",
      },
      {
        label: intl.formatMessage({ id: "service_provider" }),
        value: "SERVICE_PROVIDER",
      },
    ]);

    api
      .get(`/admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${!adminToken ? token : adminToken}`,
        },
      })
      .then((eventResponse) => {
        const events = eventResponse.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.idNumber,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        setAccountData();
        handleLoading(false);
      });
  }, [addToast, adminToken, handleLoading, intl, token]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  useEffect(() => {
    formRef.current.setFieldValue("account");
    if (selectedEvent && selectedRole) {
      handleLoading(true);
      api
        .patch(
          `/users/by-event-and-role`,
          {
            eventId: selectedEvent,
            roleType: selectedRole,
            useEmailInTheServiceProviderLabel: false,
            useCancelledOpportunities: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${!adminToken ? token : adminToken}`,
            },
          }
        )
        .then((result) => setAccounts(result.data.accountDropdownOutputList))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    addToast,
    intl,
    selectedEvent,
    selectedRole,
    token,
    handleLoading,
    adminToken,
  ]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        if (isAccountLoading) return;
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        await handleAccountLoading(true);

        api
          .post(
            `/auth/change-admin-staff-sign-in`,
            {
              systemUserId: data.account,
              loggedInUserId: loggedInUserId,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${!adminToken ? token : adminToken}`,
              },
            }
          )
          .then((result) => setAccountData(result.data))
          .catch((err) => {
            if (err.response.data.code === -2025) {
              addToast({
                type: "info",
                title: intl.formatMessage({
                  id: "info",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              });
            } else {
              addToast({
                type: "error",
                title: intl.formatMessage({
                  id: "error",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              });
            }
            handleAccountLoading(false);
          })
          .finally(() => setLoading(false));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [
      addToast,
      adminToken,
      handleAccountLoading,
      intl,
      isAccountLoading,
      loading,
      schema,
      token,
    ]
  );

  const handleReturnToUser = useCallback(async () => {
    setLoading(true);
    if (loading) {
      return;
    }

    await handleAccountLoading(true);

    api
      .post(
        `/auth/change-admin-staff-sign-in`,
        {
          systemUserId: 0,
          loggedInUserId: loggedInUserId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${!adminToken ? token : adminToken}`,
          },
        }
      )
      .then((result) => setAccountData(result.data))
      .catch((err) => {
        if (err.response.data.code === -2025) {
          addToast({
            type: "info",
            title: intl.formatMessage({
              id: "info",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        } else {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        }
        handleAccountLoading(false);
      })
      .finally(() => setLoading(false));
  }, [addToast, adminToken, handleAccountLoading, intl, loading, token]);

  useEffect(() => {
    if (isAccountLoading && accountData) {
      dispatch(
        authSuccess({
          user: accountData.user,
          token: accountData.token,
          customerCenterToken: accountData.customerCenterToken,
          accountCreated: accountData.accountCreated,
          keepSignedIn,
          serviceProviderLoggingAsAnExhibitor:
            accountData.serviceProviderLoggingAsAnExhibitor,
          adminToken: accountData.adminToken,
        })
      );
      dispatch(
        changeApplicationLanguage({
          code: accountData.user.language,
        })
      );
      handleAccountLoading(false);
      history.push("/checklist");
    }
  }, [
    accountData,
    dispatch,
    handleAccountLoading,
    history,
    isAccountLoading,
    keepSignedIn,
  ]);

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.impersonate_user" })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              is_printed: {
                label: intl.formatMessage({ id: "all" }),
                value: null,
              },
            }}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="impersonate_user" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <LinkButton onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </LinkButton>
                  <BadgeContainer onClick={() => formRef.current.submitForm()}>
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({ id: "impersonate_user" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                  {adminToken && (
                    <BadgeContainer onClick={handleReturnToUser}>
                      <Badge
                        color="#002244"
                        text={intl.formatMessage({ id: "return_to_user" })}
                        fontSize="12px"
                      />
                    </BadgeContainer>
                  )}
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <SelectWithLabel
                name="event"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "event_edition" })}
                placeholder={intl.formatMessage({ id: "event_edition" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={events}
                onChange={(event) => setSelectedEvent(event?.value)}
                required
              />
              <SelectWithLabel
                name="role"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "type" })}
                placeholder={intl.formatMessage({ id: "type" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={roles}
                onChange={(role) => setSelectedRole(role?.value)}
                required
              />
              <SelectWithLabel
                name="account"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "account" })}
                placeholder={intl.formatMessage({ id: "account" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={accounts}
                required
              />
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
    </AnimatedMainContainer>
  );
};
export default ImpersonateUser;
