import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as XLSX from "xlsx";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import FileSaver from "file-saver";
import Dropzone from "../../components/Dropzone";
import Toggle from "../../components/Toggle";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  ActionContainer,
  BadgeContainer,
  FileDropzone,
  NumericalInfoContainer,
  NumericalInfoText,
  ImportErrorContainer
} from "./styles";

import Badge from "../../components/Badge";
import { useLoading } from "../../hooks/loading";
import api from "../../service";
import getValidationErrors from "../../utils/getValidationErrors";
import SelectWithLabel from "../../components/SelectWithLabel";

const ImportCollaborator = () => {
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [events, setEvents] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [importOutput, setImportOutput] = useState();
  const [isFastTrack, setIsFastTrack] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedRoleType, setSelectedRoleType] = useState();

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    role: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    user: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    fast_track: Yup.bool().nullable(),
    file: Yup.array()
      .nullable()
      .min(1, intl.formatMessage({ id: "error.field_required" })),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        var formData = new FormData();

        data.file[0]
          .slice(0, 1)
          .arrayBuffer()
          .then(() => {
            formData.set("file", data.file[0]);
            formData.set("userId", data.user);
            formData.set("fastTrack", isFastTrack)

            api
              .post("/import-collaborator",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Accept: "*/*",
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((result) => {
                if(result.data.code === 10012) {
                  addToast({
                    type: "warning",
                    title: intl.formatMessage({
                      id: "partial_success",
                    }),
                    description: intl.formatMessage({
                      id: "success." + result.data.code,
                    }),
                  });
                } else {
                  addToast({
                    type: "success",
                    title: intl.formatMessage({
                      id: "success",
                    }),
                    description: intl.formatMessage({
                      id: "success." + result.data.code,
                    }),
                  });
                }
                setImportOutput(result.data);
              })
              .catch((err) => {
                if (err.response.data.code === -10016) {
                  setImportOutput(err.response.data);
                } else {
                  setImportOutput();
                }
                addToast({
                  type: "error",
                  title: intl.formatMessage({
                    id: "error",
                  }),
                  description: intl.formatMessage({
                    id: "api.error." + err.response.data.code,
                  }),
                });
              })
              .finally(() => {
                setLoading(false);
              });
          })
          .catch(() => {
            setLoading(false);
            setImportOutput();
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "error.changed_file",
              }),
            });
            formRef.current.clearField("file");
          });
      } catch (err) {
        setLoading(false);
        setImportOutput();
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, loading, intl, schema, token]
  );

  useEffect(() => {
    handleLoading(true);

    setRoles([
      {
        label: intl.formatMessage({ id: "exhibitor" }),
        value: "EXHIBITOR",
      },
      {
        label: intl.formatMessage({ id: "sponsor_without_m2" }),
        value: "SPONSOR_WITHOUT_M2",
      },
      {
        label: intl.formatMessage({ id: "service_provider" }),
        value: "SERVICE_PROVIDER",
      },
    ]);

    api
      .get(`/admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((eventResponse) => {
        const events = eventResponse.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.idNumber,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
      formRef.current.setFieldValue("user");
      if (selectedEvent && selectedRoleType) {
        handleLoading(true);
        api
          .patch(
            `/users/by-event-and-role`,
            {
              eventId: selectedEvent,
              roleType: selectedRoleType,
              useEmailInTheServiceProviderLabel: true,
              useCancelledOpportunities: false,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => setUsers(result.data.accountDropdownOutputList))
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => handleLoading(false));
      }
    }, [
      addToast,
      intl,
      selectedEvent,
      selectedRoleType,
      token,
      handleLoading
    ]);

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event");
    formRef.current.setFieldValue("role");
    formRef.current.setFieldValue("user");
    formRef.current.clearField("file");
    setImportOutput();
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleRoleChange = (role) => {
    setImportOutput();
    setSelectedRoleType(role?.value);
    if (role?.value !== "SERVICE_PROVIDER") {
      setIsFastTrack(false);
    }
  };

  const handleDownloadTemplate = () => {
    FileSaver.saveAs(
      `${process.env.PUBLIC_URL}/collaborator_template.xlsx`,
      "Collaborator_Template.xlsx"
    );
  };

  const handleDownloadErrorReportFile = () => {
    const errorColumns = [["Colaborador", "Erro retornado"]];

    const worksheet = XLSX.utils.json_to_sheet(importOutput.errorsOutputList);
    XLSX.utils.sheet_add_aoa(worksheet, errorColumns, { origin: "A1" });

    const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
    const csvBlobData = new Blob(
        [String.fromCharCode(0xFEFF), csvOutput],
        { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(csvBlobData, "import_collaborators_errors.csv");
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.import_collaborator" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="import_collaborator" />
              </Title>
              <ActionContainer>
                <BadgeContainer onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
                <BadgeContainer onClick={() => handleDownloadTemplate()}>
                  <Badge
                    color="#33d27e"
                    text={intl.formatMessage({ id: "download_template" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  options={events}
                  onChange={(event) => {
                    setImportOutput();
                    setSelectedEvent(event?.value);
                  }}
                  required
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="role"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "type" })}
                  placeholder={intl.formatMessage({ id: "type" })}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  options={roles}
                  onChange={handleRoleChange}
                  required
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="user"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "company" })}
                  placeholder={intl.formatMessage({ id: "company" })}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  options={users}
                  onChange={() => setImportOutput()}
                  required
                />
              </Horizontal>
              {selectedRoleType === "SERVICE_PROVIDER" && (
                <Horizontal>
                  <Toggle
                    name="fast_track"
                    label={intl.formatMessage({
                      id: "fast_track",
                    })}
                    onClick={() => {
                      setImportOutput();
                      setIsFastTrack(!isFastTrack);
                    }}
                  />
                </Horizontal>
              )}
              <Horizontal>
                <FileDropzone>
                  <Dropzone
                    name="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    type="XLSX"
                    multiple={false}
                    onChange={() => setImportOutput()}
                  />
                </FileDropzone>
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="process" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
        {importOutput && (
          <>
            <NumericalInfoContainer>
              <NumericalInfoText>
                {intl.formatMessage(
                  { id: importOutput.collaboratorsImportedSuccessfully === 1
                      ? "value_registered_collaborator"
                      : "value_registered_collaborators"
                  },
                  { value: importOutput.collaboratorsImportedSuccessfully }
                )}
              </NumericalInfoText>
              <NumericalInfoText>
                {intl.formatMessage(
                  { id: importOutput.collaboratorsImportedWithError === 1
                      ? "value_error"
                      : "value_errors"
                  },
                  { value: importOutput.collaboratorsImportedWithError }
                )}
              </NumericalInfoText>
            </NumericalInfoContainer>
            {importOutput.collaboratorsImportedWithError > 0 && (
              <ImportErrorContainer>
                <BadgeContainer onClick={() => handleDownloadErrorReportFile()}>
                  <Badge
                    color="#33d27e"
                    text={intl.formatMessage({ id: "error_report" })}
                    fontSize="16px"
                  />
                </BadgeContainer>
              </ImportErrorContainer>
            )}
          </>
        )}
      </Container>
    </AnimatedMainContainer>
  );
};

export default ImportCollaborator;
