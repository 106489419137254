import React, { useEffect, useRef, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { parse } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  LinkButton,
  NotificationContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Badge from "../../components/Badge";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import Table from "../../components/Table";
import Report from "./report";

const ManageCredentialScheduleReport = () => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [events, setEvents] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [filename, setFilename] = useState();

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .patch(
            `/admin/manage-credential-schedule?eventCode=${data.event}`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.credentialScheduleReportResponse);
            setShowExport(true);
          })
          .catch((err) => {
            setData([]);
            setShowExport(false);
          })
          .finally(() => {
            setLoading(false);
            setFiltered(true);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [loading, schema, token]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.eventEditionCode,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", "");
    setShowExport(false);
    setData([]);
    setFiltered(false);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "contract" }),
        selector: (row) => row.contractNumber,
        allowOverflow: true,
        wrap: true,
        minWidth: "110px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "id_number_cnpj_passport" }),
        selector: (row) => row.accountCode,
        allowOverflow: true,
        wrap: true,
        minWidth: "155px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "company_name" }),
        selector: (row) => row.accountName,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "commercial_name" }),
        selector: (row) => row.accountAlias,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "type" }),
        sortable: true,
        cell: (row) =>
          intl.formatMessage({ id: row.systemUserType }),
        maxWidth: "120px",
        minWidth: "110px",
      },
      {
        name: intl.formatMessage({ id: "date" }),
        sortable: true,
        cell: (row) =>
          intl.formatDate(parse(row.date, "yyyy-MM-dd", new Date())),
        maxWidth: "110px",
        minWidth: "110px",
      },
      {
        name: intl.formatMessage({ id: "schedule_time" }),
        selector: (row) => row.hour,
        sortable: true,
        maxWidth: "110px",
        minWidth: "110px",
      },
      {
        name: intl.formatMessage({ id: "quantity_scheduled_credentials" }),
        selector: (row) => row.quantity,
        sortable: true,
        maxWidth: "110px",
        minWidth: "110px",
      },
      {
        name: intl.formatMessage({ id: "quantity_credentials_withdrawn" }),
        selector: (row) => row.withdrawalQuantity,
        sortable: true,
        maxWidth: "110px",
        minWidth: "110px",
      },
      {
        name: intl.formatMessage({ id: "credentials_all_withdrawn" }),
        selector: (row) =>
          row.quantity === row.withdrawalQuantity
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
        sortable: true,
        maxWidth: "110px",
        minWidth: "110px",
      },
      {
        name: intl.formatMessage({ id: "name_of_who_withdraws" }),
        selector: (row) => row.whoWithdrawsName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "contact" }),
        selector: (row) => row.whoWithdrawsPhone,
        sortable: true,
        wrap: true,
        minWidth: "200px",
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcrumb.credential_schedule_report",
      })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="export_credential_schedule_report" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer>
                    <LinkButton onClick={() => history.push("/dashboard")}>
                      <Badge
                        color="#002244"
                        text={intl.formatMessage({ id: "goback" })}
                        fontSize="12px"
                      />
                    </LinkButton>
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  options={events}
                  onChange={(event) => setFilename(event?.label?.toUpperCase())}
                  required
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                  {showExport ? (
                    <SubmitContainer>
                      <FormButton type="button" loading={loading}>
                        <Report
                          filename={`${filename}_CREDENTIALS_SCHEDULE_REPORT`}
                          data={data}
                          intl={intl}
                        >
                          <FormattedMessage id="export" />
                        </Report>
                      </FormButton>
                    </SubmitContainer>
                  ) : null}
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
          hasPagination
        />
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  );
};

export default ManageCredentialScheduleReport;
