import styled, { css } from "styled-components";

export const Container = styled.div`
  min-height: 100%;
  background-color: #002244;
  flex: 0 0 80px;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 999;
`;

export const Content = styled.div`
  max-height: calc(100vh - 72px);
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const IconContainer = styled.div`
  text-align: center;
  color: #ccc;
  margin: 8px 0px;
  margin-left: 10px;
  padding: 8px;
  border-radius: 16px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 70px;

  &:hover {
    border-radius: 16px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    svg {
      z-index: 9999;
      fill: #11a7d9;
    }
  }

  ${(props) =>
    (props.isSelected || props.active) &&
    css`
      background-color: #fff;
      &:before {
        content: "";
        position: absolute;
        background-color: transparent;
        right: 0px;
        height: 50px;
        width: 25px;
        bottom: -50px;
        border-top-right-radius: 25px;
        box-shadow: 0 -25px 0 0 #fff;
      }

      &:after {
        content: "";
        position: absolute;
        background-color: transparent;
        right: 0px;
        height: 50px;
        width: 25px;
        top: -50px;
        border-bottom-right-radius: 25px;
        box-shadow: 0 25px 0 0 #fff;
      }
      span {
        display: none;
      }
      svg {
        fill: #11a7d9;
        z-index: 9999;
      }
    `};
`;

export const IconText = styled.span`
  font-size: 8px;
  font-weight: bold;
  text-transform: uppercase;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-touch-callout: none;
  z-index: 99999;
`;

export const LogoContainer = styled.div`
  width: 100%;
  background-color: #4c637b;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 42px;
  cursor: pointer;
`;
