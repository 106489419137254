import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Route as ReactDOMRoute, Redirect, useHistory } from "react-router-dom";
import MainContainerWithSidebar from "../../components/MainContainerWithSidebar";
import LoadingOverlay from "../../components/LoadingOverlay";

const Route = ({
  isPrivate = false,
  component: Component,
  roles,
  code,
  ...rest
}) => {
  const {
    user,
    loading
  } = useSelector((state) => state.auth);
  const history = useHistory();

  const havePermission = useCallback(() => {
    const permissions = user?.permissionCategories.reduce((array, item) => {
      let arraySanitized = [];
      item.permissions.forEach((value) => {
        arraySanitized.push(value.code);
      });
      return [...array.concat(arraySanitized)];
    }, []) || [];

    return permissions.includes(code);
  }, [code, user]);

  if (history.location.pathname === "/logout" && user) {
    return (
      <ReactDOMRoute
        {...rest}
        render={() => {
          return <Component />;
        }}
      />
    );
  }

  if (loading) {
    return <div></div>;
  }

  if (user && !havePermission()) {
    <Redirect
      to={{
        pathname: "/dashboard",
      }}
    />;
  }

  if (user && isPrivate && roles) {
    if (roles.includes(user.role.type)) {
      return (
        <ReactDOMRoute
          {...rest}
          render={() => {
            return (
              <MainContainerWithSidebar>
                <Component />
              </MainContainerWithSidebar>
            );
          }}
        />
      );
    }
    return (
      <Redirect
        to={{
          pathname: "/dashboard",
        }}
      />
    );
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        /* isPrivate/user (is signed)
          true/true --> OK
          true/false --> Redirect to Logon
          false/true --> Redirect to Dashboard
          false/false --> OK
        */

        return isPrivate === !!user ? (
          isPrivate ? (
            <MainContainerWithSidebar>
              <Component />
            </MainContainerWithSidebar>
          ) : (
            <LoadingOverlay>
              <Component />
            </LoadingOverlay>
          )
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/" : '/dashboard',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
