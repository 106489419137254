import React, { 
  useEffect, 
  useState, 
  useRef,
  useMemo,
  useCallback 
} from "react";

import * as Yup from "yup";
import { Form } from "@unform/web";
import { jsPDF } from "jspdf";
import { renderToString } from 'react-dom/server'
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import api from "../../service";

import { useToast } from "../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import getValidationErrors from "../../utils/getValidationErrors";
import { useLoading } from "../../hooks/loading";

import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import SelectWithLabel from "../../components/SelectWithLabel";
import InputWithLabel from "../../components/InputWithLabel";
import Badge from "../../components/Badge";
import FormButton from "../../components/FormButton";
import Table from "../../components/Table";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  GoBackContainer,
  ActionContainer,
  LinkButton,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  NotificationContainer
} from "./styles";

import InvoicePDF from './InvoicePDF'

const Invoices = () => {
  const [data, setData] = useState()
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [clients, setClients] = useState([]);
  const [selectedClientUserId, setSelectedClientUserId] = useState();
  const [selectedClientRoleType, setSelectedClientRoleType] = useState();
  const [filtered, setFiltered] = useState(false);
  const [areEventAndClientRequired, setAreEventAndClientRequired] = useState(true);
  const [types, setTypes] = useState([]);

  const { token } = useSelector( (state) => state.auth);
  const { handleLoading } = useLoading();

  
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const history = useHistory();

  const schema = Yup.object().shape({
    event: Yup.string().nullable().test(
      "is-order-number-filled",
      intl.formatMessage({ id: "error.field_required_invoice" }),
      () => {
        if (!formRef.current.getFieldValue('orderNumber')
        && !formRef.current.getFieldValue('event')) return false;
        return true
      }
    ),
    roleType: Yup.string().nullable().test(
      "is-order-number-filled",
      intl.formatMessage({ id: "error.field_required_invoice" }),
      () => {
        if (!formRef.current.getFieldValue('orderNumber')
        && !formRef.current.getFieldValue('roleType')) return false;
        return true
      }
    ),
    client: Yup.string().nullable().test(
      "is-order-number-filled",
      intl.formatMessage({ id: "error.field_required_invoice" }),
      () => {
        if (!formRef.current.getFieldValue('orderNumber')
        && !formRef.current.getFieldValue('client')) return false;
        return true
      }
    ),
    orderNumber: Yup.string().nullable(),
  });


  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "event_edition" }),
        selector: (row) => row.eventEditionName,
        allowOverflow: true,
        wrap: true,
        minWidth: "145px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "customer" }),
        selector: (row) => row.clientName,
        allowOverflow: true,
        wrap: true,
        minWidth: "145px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "contract_number" }),
        selector: (row) => row.contractNumber,
        allowOverflow: true,
        wrap: true,
        minWidth: "145px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "order_number" }),
        selector: (row) => row.orderNumber,
        allowOverflow: true,
        wrap: true,
        minWidth: "145px",
        sortable: true,
      },
      {
        cell: (row) => (
          <Badge
            color="#002244"
            text={intl.formatMessage({ id: "see_invoice" })}
            onClick={()=>handleInvoiceClick(row.orderNumber)}
          />
        ),
        right: true,
      },
    ],
    [intl]
  );


  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  useEffect(() => {
    handleLoading(true);

    setTypes([
      {
        label: intl.formatMessage({ id: "exhibitor" }),
        value: "EXHIBITOR",
      },
      {
        label: intl.formatMessage({ id: "sponsor_without_m2" }),
        value: "SPONSOR_WITHOUT_M2",
      },
      {
        label: intl.formatMessage({ id: "service_provider" }),
        value: "SERVICE_PROVIDER",
      },
    ]);
  
    api
      .get(`/admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((eventResponse) => {
        const events = eventResponse.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.eventEditionCode,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, handleLoading, intl, token]);

  useEffect(() => {
    if (!selectedEvent || !selectedClientRoleType) return

    handleLoading(true);
    api
      .get("/admin/account/ecommerce/event", {
        params: {
          event_code: selectedEvent,
          role_type: selectedClientRoleType,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const exhibitors = result.data.users.map((user) => ({
          label: user.commercialName,
          value: user.id,
          email: user.email,
        }));
        setClients(exhibitors);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
    
  }, [
    selectedEvent,
    selectedClientRoleType,
    addToast,
    intl,
    token,
    handleLoading,
  ]);

  useEffect(()=>{
    clearFields()
  },[])

  const handleSubmit =  useCallback(
    async (data) => {
      try {
      formRef.current?.setErrors({});
      await schema.validate(data, { abortEarly: false });
      handleLoading(true);
      api
        .patch(
          "/invoice",
          {
            eventEditionCode: selectedEvent,
            roleType: selectedClientRoleType,
            userId: selectedClientUserId,
            orderNumber: data.orderNumber
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setData(response.data.invoiceFilterOutputList)
          setFiltered(true);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
      } catch (err) {
        handleLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
      }
    }
  );

  const handleInvoiceClick = (orderId) =>{
    handleLoading(true);
  
    api
      .get(`/invoice/${orderId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((eventResponse) => {
        generatePDF(eventResponse.data.invoiceOutput)
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        handleLoading(false);
      });
  }


  const generatePDF = (order) =>{
    const doc = new jsPDF('p', 'pt', 'a4', true)
    handleLoading(true)

    const InvoicePDFAsString = renderToString(<InvoicePDF order={order}/>)

    doc.html(InvoicePDFAsString, {
      callback: function (doc) {
        doc.save(`${order.invoiceNumber}.pdf`)
        handleLoading(false)
      }
    })
  }

  const handleEventChange = (event) => {
    formRef.current.setFieldValue("client")
    setSelectedEvent(event?.value)
  }

  const handleRoleTypeChange = (type) => {
    formRef.current.setFieldValue("client")
    setSelectedClientRoleType(type?.value)
  }

  const handleOrderNumberChange = (event) => {
    !event?.target.value
      ? setAreEventAndClientRequired(true)
      : setAreEventAndClientRequired(false)
  }

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event");
    formRef.current.setFieldValue("roleType");
    formRef.current.setFieldValue("client");
    setFiltered(false);
    setData([]);
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              is_printed: {
                label: intl.formatMessage({ id: "all" }),
                value: null,
              },
            }}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="invoices" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <LinkButton onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </LinkButton>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  options={events}
                  onChange={handleEventChange}
                  required={areEventAndClientRequired}
                />
                <SelectWithLabel
                  name="roleType"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "type" })}
                  placeholder={intl.formatMessage({ id: "type" })}
                  options={types}
                  onChange={handleRoleTypeChange}
                  required={areEventAndClientRequired}
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="client"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "customer" })}
                  placeholder={intl.formatMessage({ id: "customer" })}
                  options={clients}
                  onChange={(event)=> 
                    setSelectedClientUserId(event?.value)
                  }
                  required={areEventAndClientRequired}
                />
                <InputWithLabel
                  name="orderNumber"
                  label={intl.formatMessage({ id: "order_number" })}
                  placeholder={intl.formatMessage({id: "order_number"})}
                  onChange={handleOrderNumberChange}
                />
              </Horizontal>
            </FieldsContainer>
            <Horizontal>
              <FilterActionsContainer>
                <SubmitContainer>
                  <FormButton
                    color="#C9C9C9"
                    type="button"
                    onClick={clearFields}
                  >
                    <FormattedMessage id="clear_fields" />
                  </FormButton>
                </SubmitContainer>
                <SubmitContainer>
                  <FormButton type="submit">
                    <FormattedMessage id="search" />
                  </FormButton>
                </SubmitContainer>
              </FilterActionsContainer>
            </Horizontal>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
          hasPagination
        />
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  )
}

export default Invoices