import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import InputWithLabel from "../../components/InputWithLabel";
import DatePickerWithLabel from "../../components/DatePickerWithLabel";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { format, isBefore, isEqual } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
  LinkButtomSalesForce,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import { useLoading } from "../../hooks/loading";
import axios from "axios";
import { formatDate } from "@formatjs/intl";
import opportunityStatusTypesConstants from "../../constants/opportunityStatusTypes";

const Contracts = () => {
  const { user, token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [urlSalesForce, setUrlSalesForce] = useState("");
  const [formWithSearchedValues, setFormWithSearchedValues] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const opportunityStatusTypes = opportunityStatusTypesConstants();
  const isAdministrator = user?.role?.type === "ADMINISTRATOR";

  const schema = Yup.object().shape({
    event: Yup.string().nullable(),
    customer: Yup.string().nullable(),
    booth_number: Yup.string().nullable(),
    contract_number: Yup.string().nullable(),
    contract_type: Yup.string().nullable(),
    integration_start_period: Yup.string().nullable(),
    integration_end_period: Yup.string()
      .nullable()
      .when("integration_start_period", {
        is: (integration_start_period) => integration_start_period,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: "error.field_required",
            })
          ),
      })
      .test(
        "is-incorrect",
        intl.formatMessage({
          id: "error.initial_date_later",
        }),
        () => {
          const integration_start_period = formRef.current.getFieldValue(
            "integration_start_period"
          );
          const integration_end_period = formRef.current.getFieldValue(
            "integration_end_period"
          );

          if (integration_start_period && integration_end_period) {
            return (
              isBefore(
                new Date(integration_start_period),
                new Date(integration_end_period)
              ) ||
              isEqual(
                new Date(integration_start_period),
                new Date(integration_end_period)
              )
            );
          }
          return true;
        }
      )
      .test(
        "is-incorrect2",
        intl.formatMessage({ id: "error.no_initial_date" }),
        () => {
          const integration_start_period = formRef.current.getFieldValue(
            "integration_start_period"
          );
          const integration_end_period = formRef.current.getFieldValue(
            "integration_end_period"
          );

          if (!integration_start_period && integration_end_period) {
            return false;
          }

          return true;
        }
      ),
  });

  const fetchData = useCallback(
    async (page, size = perPage) => {
      try {
        setLoading(true);

        api
          .patch(
            `/admin/opportunity?page=${page}&per_page=${size}`,
            {
              contractNumber: formRef.current.getFieldValue("contract_number"),
              accountName: formRef.current.getFieldValue("customer"),
              type: formRef.current.getFieldValue("contract_type"),
              eventEditionName: formRef.current.getFieldValue("event"),
              boothNumber: formRef.current.getFieldValue("booth_number"),
              integrationStartPeriod:
                formRef.current.getFieldValue("integration_start_period") !== ""
                  ? formRef.current.getFieldValue("integration_start_period")
                  : null,
              integrationEndPeriod:
                formRef.current.getFieldValue("integration_end_period") !== ""
                  ? formRef.current.getFieldValue("integration_end_period")
                  : null,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.opportunityInformation);
            setTotalRows(result.data.paginationInfo.totalElements);
            setLoading(false);
            sessionStorage.setItem(
              "@informacp/contractNavigation",
              JSON.stringify({
                eventName: formRef.current.getFieldValue("event"),
                accountName: formRef.current.getFieldValue("customer"),
                contractNumber:
                  formRef.current.getFieldValue("contract_number"),
                contractType: formRef.current.getFieldValue("contract_type"),
                contractSearchResult: result.data.opportunityInformation,
              })
            );
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, perPage, token]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        setCurrentPage(1);

        api
          .patch(
            `/admin/opportunity?page=0&per_page=${perPage}`,
            {
              contractNumber: data.contract_number,
              accountName: data.customer,
              type: data.contract_type,
              eventEditionName: data.event,
              boothNumber: data.booth_number,
              integrationStartPeriod:
                data.integration_start_period !== ""
                  ? data.integration_start_period
                  : null,
              integrationEndPeriod:
                data.integration_end_period !== ""
                  ? data.integration_end_period
                  : null,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.opportunityInformation);
            setTotalRows(result.data.paginationInfo.totalElements);
            setLoading(false);
            sessionStorage.setItem(
              "@informacp/contractNavigation",
              JSON.stringify({
                eventName: data.event,
                accountName: data.customer,
                contractNumber: data.contract_number,
                contractType: data.contract_type,
                contractSearchResult: result.data.opportunityInformation,
              })
            );
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, schema, token, loading, perPage]
  );

  useEffect(() => {
    if (process.env.REACT_APP_ENVIROMENT === "prd") {
      setUrlSalesForce(`https://informage.lightning.force.com/`);
    } else {
      setUrlSalesForce(`https://informage--uat.lightning.force.com/`);
    }
    handleLoading(true);
    if (
      history.location.state != null &&
      history.location.state.prevPath.pathname.includes("/contract/")
    ) {
      if (sessionStorage.getItem("@informacp/contractNavigation") != null) {
        setData(
          JSON.parse(sessionStorage.getItem("@informacp/contractNavigation"))
            .contractSearchResult
        );
        api
          .get("/admin/events/eventEditionNames", {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            const events = result.data.output.map((event) => ({
              label: event.eventEditionName,
              value: event.eventEditionName,
            }));
            let eventsSort = [...events].sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            );
            setEvents(eventsSort);
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            handleLoading(false);
          });
      } else {
        handleLoading(false);
      }
    } else {
      callOpportunitiesApi();
    }
  }, [addToast, handleLoading, intl, token, history]);

  const callOpportunitiesApi = () => {
    clearSessionStorage();
    handleLoading(true);
    axios
      .all([
        api.get("/admin/events/eventEditionNames", {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }),
        api.patch(
          `/admin/opportunity?page=0&per_page=${perPage}`,
          {
            contractNumber: null,
            accountName: null,
            type: null,
            eventEditionName: null,
            boothNumber: null,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        ),
      ])
      .then(
        axios.spread((editionNamesRes, opportunityRes) => {
          const events = editionNamesRes.data.output.map((event) => ({
            label: event.eventEditionName,
            value: event.eventEditionName,
          }));
          let eventsSort = [...events].sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          setEvents(eventsSort);
          setData(opportunityRes.data.opportunityInformation);
          setTotalRows(opportunityRes.data.paginationInfo.totalElements);
        })
      )
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const handleUpdateBySalesforce = (id) => {
    handleLoading(true);
    api
      .put(`/integration/update-opportunity-by-salesforce/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.opportunity_updated_by_salesforce",
          }),
        });
        fetchData(currentPage - 1);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", {
      label: intl.formatMessage({ id: "event_edition" }),
      value: null,
    });
    formRef.current.setFieldValue("customer", "");
    formRef.current.setFieldValue("contract_number", "");
    formRef.current.setFieldValue("contract_type", "");
    formRef.current.setFieldValue("booth_number", "");
    setCurrentPage(1);
    callOpportunitiesApi();
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handlePageChange = (page) => {
    fetchData(page - 1);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page - 1, newPerPage);
    setPerPage(newPerPage);
  };

  useEffect(() => {
    const contractNavigationFromSessionStorage = sessionStorage.getItem(
      "@informacp/contractNavigation"
    );

    if (contractNavigationFromSessionStorage) {
      const { eventName, accountName, contractNumber, contractType } =
        JSON.parse(contractNavigationFromSessionStorage);
      const event = events.find((item) => item.value === eventName);
      setFormWithSearchedValues({
        event: event,
        contract_number: contractNumber,
        contract_type: contractType,
        customer: accountName,
      });
    }
  }, [intl, events]);

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "contract_number" }),
        selector: (row) => row.contractNumber,
        width: "150px",
      },
      {
        name: intl.formatMessage({ id: "integration_date" }),
        selector: (row) =>
          row.createdAt ? format(new Date(row.createdAt), "dd/MM/yyyy") : "-",
        width: "150px",
      },
      {
        name: intl.formatMessage({ id: "event_edition" }),
        selector: (row) => row.eventEditionName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "250px",
      },
      {
        name: intl.formatMessage({ id: "client_exhibitor" }),
        selector: (row) => row.accountName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "250px",
      },
      {
        name: intl.formatMessage({ id: "type" }),
        selector: (row) => row.type,
        sortable: true,
        width: "150px",
      },
      {
        name: intl.formatMessage({ id: "opportunity_status" }),
        selector: (row) => row.status,
        cell: (row) =>
          opportunityStatusTypes.filter((pt) => pt.value === row.status)[0]?.label,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
      },
      {
        name: intl.formatMessage({ id: "salesman" }),
        selector: (row) => row.owner,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
      },
      {
        name: intl.formatMessage({ id: "booth_number" }),
        selector: (row) => row.boothNumber,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
      },
      {
        cell: (row) => (
          <LinkButton to={`/contract/${row.opportunityId}`}>
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "see_details" })}
            />
          </LinkButton>
        ),
        right: true,
        width: "150px",
      },
      {
        cell: (row) => (
          <LinkButtomSalesForce
            href={`${urlSalesForce}lightning/r/SBQQ__Quote__c/${row.opportunityId}/view`}
            target="_blank"
          >
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "salesforce" })}
            />
          </LinkButtomSalesForce>
        ),
        right: true,
        width: "150px",
      },
      {
        cell: (row) => (
          isAdministrator ? (
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "update_button" })}
              onClick={() => handleUpdateBySalesforce(row.opportunityId)}
            />
          ) : (
            <div></div>
          )
        ),
        right: true,
        width: isAdministrator ? "150px" : "0px",
      },
    ],
    [
      intl,
      urlSalesForce,
      opportunityStatusTypes,
      handleUpdateBySalesforce
    ]
  );

  const clearSessionStorage = () => {
    sessionStorage.removeItem("@informacp/contractNavigation");
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.contracts" })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={formWithSearchedValues}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_contracts" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  options={events}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                />
                <InputWithLabel
                  name="customer"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "client_exhibitor" })}
                />
              </Horizontal>
              <Horizontal>
                <InputWithLabel
                  name="booth_number"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "booth_number" })}
                />
                <InputWithLabel
                  name="contract_number"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract_number" })}
                />
              </Horizontal>
              <Horizontal>
                <InputWithLabel
                  name="contract_type"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract_type" })}
                />
                <DatePickerWithLabel
                  name="integration_start_period"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "start_integration_period" })}
                  labelOrientation="vertical"
                />
                <DatePickerWithLabel
                  name="integration_end_period"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "end_integration_period" })}
                  labelOrientation="vertical"
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      <Table
        data={data}
        columns={columns}
        progressPending={loading}
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </AnimatedMainContainer>
  );
};

export default Contracts;
