import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ExclamationOutlined } from "@ant-design/icons";

import {
  getListCredentials,
  getCredential,
  deleteAssociateCredential,
  getQuotaCredential,
} from "../../../redux/modules/credentials/actions";

import { DialogContext } from "../../../hooks/dialog/context";
import useNotification from "../../../hooks/newUXNotification";
import { useLoading } from "../../../hooks/loading";

import { PlusOutlined } from "@ant-design/icons";

import CredentialsHeader from "../Components/Header";

import Spinner from "../../../newUXAssets/icons/Spinner";
import Check from "../../../newUXAssets/icons/Check";
import UserCircle from "../../../newUXAssets/icons/UserCircle";
import Edit from "../../../newUXAssets/icons/Edit";
import Trash from "../../../newUXAssets/icons/Trash";
import XCircle from "../../../newUXAssets/icons/XCircle";
import CheckCircle from "../../../newUXAssets/icons/CheckCircle";

import Button from "../../../newUXComponents/Button";
import Table from "../../../newUXComponents/Table";

import FormModal from "./Modal/FormModal";

import {
  Container,
  Title,
  Content,
  Cards,
  Card,
  CardBody,
  CardText,
  CardIcon,
  ContainerBtn,
  Actions,
  BtnActions,
  DeletedCredentialBox
} from "./styles";
import ConfirmationModal from "../../../newUXComponents/Modal/ConfirmationModal";

const Associate = () => {
  const intl = useIntl();
  const history = useHistory();
  const { createDialog } = useContext(DialogContext);
  const dispatch = useDispatch();
  const notification = useNotification();

  const { handleLoading } = useLoading();

  const { user } = useSelector((state) => state.auth);

  const { associatesList, typesCredentials, quota } = useSelector(
    (state) => state.credentials
  );
  const { contract, event } = useSelector((state) => state.initialSettings);

  const [tablePagination, setTablePagination] = useState({
    current: 0,
    defaultCurrent: 0,
    pageSize: 10,
    showQuickJumper: true,
    total: 0,
  });

  const haveAccount = useMemo(() => {
    return user?.role?.type === "EXHIBITOR"
      || user?.role?.type === "SPONSOR_WITHOUT_M2";
  }, [user]);

  const callbackPaginationInfo = useCallback((pagination) => {
    handleLoading(false);
    setTablePagination({
      current: pagination.pageNumber + 1,
      defaultCurrent: 1,
      pageSize: pagination.maxSize,
      showQuickJumper: true,
      total: pagination.totalElements,
    });
  }, []);

  const callbackError = useCallback(
    (message) => {
      notification.displayError(
        intl.formatMessage({
          id: "api.error." + message,
        }),
        "get-credentials-associate-error"
      );
    },
    [notification, intl]
  );

  const handleGetInfoTable = useCallback(
    (page = 0, pageSize = 10, search = "") => {
      handleLoading(true);
      dispatch(
        getListCredentials(
          {
            page,
            per_page: pageSize,
            opportunity_id: contract.value,
            event_id: event.value,
            search,
          },
          callbackPaginationInfo,
          callbackError
        )
      );
      dispatch(
        getQuotaCredential(
          {
            event_id: event.value,
            opportunity_id: contract.value,
          },
          callbackError
        )
      );
    },
    [contract, event, callbackPaginationInfo, callbackError, dispatch]
  );

  const handleModalForm = useCallback(
    (item) => {
      createDialog({
        id: "modal-associate-credential",
        Component: FormModal,
        props: {
          data: item,
          isExhibitor: haveAccount,
          typesCredentials: typesCredentials,
          idContract: contract.value,
          eventId: event.value,
          callbackSuccess: handleGetInfoTable,
        },
      });
    },
    [
      createDialog,
      haveAccount,
      contract,
      event,
      typesCredentials,
      // eslint-disable-next-line no-use-before-define
      handleGetInfoTable,
    ]
  );

  const callbackSuccess = useCallback(() => {
    notification.displaySuccess(
      intl.formatMessage({
        id: "success.credential_unassociated",
      }),
      "remove-credentials-associate-success"
    );
    handleGetInfoTable();
  }, [notification, intl]);

  const handleEditCredential = useCallback(
    (id) => {
      dispatch(getCredential(id, handleModalForm, callbackError));
    },
    [callbackError, dispatch, handleModalForm]
  );

  const handleDeleteAssociateCredential = useCallback(
    (id) => {
      createDialog({
        id: "modal-associate-credential",
        Component: ConfirmationModal,
        props: {
          title: intl.formatMessage({ id: "delete_membership" }),
          message: intl.formatMessage({ id: "delete_credential_association" }),
          confirmation: () => {
            dispatch(
              deleteAssociateCredential(id, callbackSuccess, callbackError)
            );
          },
        },
      });
    },
    [callbackError, callbackSuccess, dispatch, handleGetInfoTable]
  );

  const columns = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: "credential" }),
        dataIndex: "collaboratorName",
        key: "collaboratorName",

        render: (text, record) => {
          if (!record.collaboratorStatus) {
            return (
              <DeletedCredentialBox>
                <p style={{fontSize: 24, color: 'red'}}>!</p><p>{text}</p> 
              </DeletedCredentialBox>
            );
          }

          return text;
        },
      },
      {
        title: intl.formatMessage({ id: "type" }),
        dataIndex: "category",
        key: "category",
        render: (text) => {
          return text || intl.formatMessage({ id: "contract" });
        },
      },
      {
        title: intl.formatMessage({ id: "credential_name" }),
        dataIndex: "credentialName",
        key: "credentialName",
        width: 200,
      },
      {
        title: intl.formatMessage({ id: "assignment" }),
        dataIndex: "jobTitle",
        key: "jobTitle",
      },
      {
        title: intl.formatMessage({ id: "press" }),
        dataIndex: "printed",
        key: "printed",
        align: "center",
        render: (text) => {
          if (text) {
            return <CheckCircle color="#49D78C" size={20} filled />;
          }

          return <XCircle />;
        },
      },
      {
        title: intl.formatMessage({ id: "scheduled" }),
        dataIndex: "credentialScheduleId",
        key: "credentialScheduleId",
        align: "center",
        render: (text) => {
          if (text) {
            return <CheckCircle color="#49D78C" size={20} filled />;
          }

          return <XCircle />;
        },
      },
      {
        title: intl.formatMessage({ id: "withdrawn" }),
        dataIndex: "protocolId",
        key: "protocolId",
        align: "center",
        render: (text) => {
          if (text) {
            return <CheckCircle color="#49D78C" size={20} filled />;
          }

          return <XCircle />;
        },
      },
      {
        title: intl.formatMessage({ id: "actions" }),
        dataIndex: "actions",
        key: "actions",
        render: (_, record) => {
          return (
            <Actions>
              <BtnActions
                onClick={() =>
                  !record.credentialScheduleId &&
                  !record.protocolId &&
                  record.collaboratorStatus &&
                  handleEditCredential(record.id)
                }
                $disabled={
                  record.credentialScheduleId || record.protocolId || !record.collaboratorStatus
                }
              >
                <Edit />
              </BtnActions>
              <BtnActions
                $disabled={record.printed}
                $isDelete
                onClick={() =>
                  !record.printed && handleDeleteAssociateCredential(record.id)
                }
              >
                <Trash />
              </BtnActions>
            </Actions>
          );
        },
      },
    ],
    [handleDeleteAssociateCredential, handleEditCredential, intl]
  );

  useEffect(() => {
    if (!contract.value || !event.value) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        "event-not-selected-message"
      );
    } else {
      handleGetInfoTable();
      notification.close("event-not-selected-message");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract.value, event.value]);

  return (
    <Container>
      <Title>{intl.formatMessage({ id: "credentials" })}</Title>

      <CredentialsHeader isAssociateSelected />
      {contract?.value && event?.value && (
        <>
          <Cards>
            <Card>
              <CardBody>
                <CardText>
                  <span>{intl.formatMessage({ id: "available" })}</span>
                  <span>{quota?.available || 0}</span>
                </CardText>
                <CardIcon>
                  <Spinner />
                </CardIcon>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardText>
                  <span>{intl.formatMessage({ id: "associates" })}</span>
                  <span>{quota?.used || 0}</span>
                </CardText>
                <CardIcon>
                  <Check color="#6C6C6C" size={24} />
                </CardIcon>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardText>
                  <span>{intl.formatMessage({ id: "total" })}</span>
                  <span>{quota?.total || 0}</span>
                </CardText>
                <CardIcon>
                  <UserCircle color="#6C6C6C" size={24} />
                </CardIcon>
              </CardBody>
            </Card>
          </Cards>
          <ContainerBtn>
            <Button
              label={intl.formatMessage({ id: "associate_credential" })}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleModalForm()}
            />
            <Button
              label={intl.formatMessage({ id: "acquire_credential" })}
              type="link"
              onClick={() => history.push("/ecommerce")}
              icon={<PlusOutlined />}
            />
          </ContainerBtn>

          <Content>
            <Table
              title={intl.formatMessage({ id: "associated_credentials" })}
              dataSource={associatesList}
              columns={columns}
              tablePagination={tablePagination}
              handleGetInfoTable={handleGetInfoTable}
            />
          </Content>
        </>
      )}
    </Container>
  );
};

export default Associate;
