import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-left: 80px;
  ${(props) =>
    !props.sidebarIsVisible &&
    css`
      margin-left: 0px;
    `}
`;

export const Content = styled.div`
  display: flex;
  width: calc(100vw - 90px);

  ${(props) =>
    !props.sidebarIsVisible &&
    css`
      width: 100vw;
    `}

  /* @media (min-height: 940px) {
    margin-top: 72px;
  } */

  flex: 1;
  background-color: #dadada;
`;
