import React, { useState, useMemo, useEffect, useContext } from "react";
import { Form, Switch } from "antd";
import InputMask from "react-input-mask";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BackArrow from "../../../../newUXAssets/icons/BackArrow";
import Input from "../../../../newUXComponents/Input";
import Select from "../../../../newUXComponents/Select";
import Button from "../../../../newUXComponents/Button";
import TelephoneInput, {getFullTelephoneNumber} from "../../../../newUXComponents/TelephoneInput";
import useNotification from "../../../../hooks/newUXNotification";
import { DialogContext } from "../../../../hooks/dialog/context";
import { useLoading } from "../../../../hooks/loading";
import {
  postNewEmployee,
  getNewEmployeeCategories,
} from "../../../../redux/modules/employee/actions";

import { validation, INITIAL_VALUES } from "./validations";
import ConfirmationModal from "./ConfirmationModal";
import {
  Container,
  ReturnLink,
  Title,
  ButtonContainer,
  SwitchContainer,
  FormContainer,
} from "./styles";

const NewEmployee = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const notification = useNotification();
  const { handleLoading } = useLoading();
  const { createDialog } = useContext(DialogContext);

  const [isCreateCredential, setIsCreateCredential] = useState(false);
  const [documentMask, setDocumentMask] = useState("999.999.999-99");
  const [documentLabel, setDocumentLabel] = useState(
    intl.formatMessage({ id: "document_cpf" })
  );

  const { event, contract } = useSelector((state) => state.initialSettings);
  const { categories } = useSelector((state) => state.employee);
  const { user, permission } = useSelector((state) => state.auth);

  const isPortuguese = useMemo(() => intl.locale === "pt", [intl]);

  const havePermission = useMemo(() => {
    return permission.includes("MANAGE_CREDENTIALS");
  }, [permission]);

  const handleTypeChanges = (value) => {
    switch (value) {
      case false:
        setDocumentLabel(intl.formatMessage({ id: "document_cpf" }));
        setDocumentMask("999.999.999-99");
        break;
      case true:
        setDocumentLabel(intl.formatMessage({ id: "document_passport" }));
        setDocumentMask("");
        break;
      default:
        setDocumentLabel(intl.formatMessage({ id: "document" }));
        break;
    }
    form.setFieldValue("document", "");
  };

  const callBackError = (message) => {
    handleLoading(false);
    notification.displayError(
      intl.formatMessage({ id: "api.error." + message }),
      "post-new-employee-error-message"
    );
  };

  const callBackSuccess = (message, key) => {
    handleLoading(false);
    notification.displaySuccess(
      intl.formatMessage({ id: "success." + message }),
      "post-new-employee-success-message"
    );
    setIsCreateCredential(false);
    createDialog({
      id: `new-employee-confirmation-display-${key}`,
      Component: ConfirmationModal,
      props: {
        onConfirmation: handleConfirmation,
        handleClose: handleGoBack,
      },
    });
  };

  const handleGetNewEmployeeCategories = (value) => {
    setIsCreateCredential(value);
    if (value) {
      dispatch(
        getNewEmployeeCategories({
          selectedEvent: event.value,
          selectedContract: contract.value,
          callbackError: callBackError,
          isPortuguese,
        })
      );
    }
  };

  const onFinish = (values) => {
    if (!event || !contract) return;

    handleLoading(true);

    const newEmployee = {
      international: values.nationality,
      name: values.name,
      lastName: values.last_name,
      credentialName: values.credential_name,
      document: values.document,
      jobTitle: values.assignment,
      email: values.email,
      address: values.address,
      telephone: getFullTelephoneNumber(
        values.telephone,
        values.telephone_selected_country.value
      ),
      createCredential: isCreateCredential,
      eventId: event.value,
      opportunityId: contract.value,
      categoryId: values.category,
    };
    dispatch(
      postNewEmployee({ employee: newEmployee, callBackError, callBackSuccess })
    );
  };

  const handleGoBack = () => {
    history.push("/credentials/register");
  };

  const handleConfirmation = () => {
    form.resetFields();
    form.setFieldValue("nationality", false);
    form.setFieldValue("create_credential", false);
    form.setFieldValue("telephone_selected_country", {
      value: "BR",
    });
    setDocumentLabel(intl.formatMessage({ id: "document_cpf" }));
    setDocumentMask("999.999.999-99");
  };

  useEffect(() => {
    if (!event?.value && !contract?.value) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        "event-not-selected-message"
      );
    } else {
      notification.close("event-not-selected-message");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.value, contract?.value]);

  return (
    <Container>
      <ReturnLink onClick={handleGoBack}>
        <BackArrow />
        <p>{intl.formatMessage({ id: "goback" })}</p>
      </ReturnLink>
      <Title>{intl.formatMessage({ id: "new_collaborator" })}</Title>
      {contract?.value && (
        <FormContainer>
          <Form
            layout="vertical"
            form={form}
            name="new-employee"
            onFinish={onFinish}
            initialValues={INITIAL_VALUES}
          >
            <Select
              name="nationality"
              label={intl.formatMessage({ id: "nationality" })}
              showArrow
              allowClear
              placeholder={intl.formatMessage({ id: "nationality" })}
              rules={validation("nationality", intl)}
              onChange={(e) => handleTypeChanges(e)}
              options={[
                {
                  label: intl.formatMessage({ id: "national" }),
                  value: false,
                },
                {
                  label: intl.formatMessage({ id: "international" }),
                  value: true,
                },
              ]}
            />
            <Input
              type="text"
              name="name"
              label={intl.formatMessage({ id: "name" })}
              placeholder={intl.formatMessage({ id: "name" })}
              rules={validation("name", intl)}
            />
            <Input
              type="text"
              name="last_name"
              label={intl.formatMessage({ id: "last_name" })}
              placeholder={intl.formatMessage({ id: "last_name" })}
              rules={validation("last_name", intl)}
            />
            <Input
              type="text"
              name="credential_name"
              label={intl.formatMessage({ id: "credential_name" })}
              placeholder={intl.formatMessage({ id: "credential_name" })}
              rules={validation("credential_name", intl)}
            />
            <Input
              type="text"
              name="document"
              as={InputMask}
              mask={documentMask}
              placeholder={documentLabel}
              label={documentLabel}
              rules={validation("document", intl)}
            />
            <Input
              type="text"
              name="assignment"
              label={intl.formatMessage({ id: "assignment" })}
              placeholder={intl.formatMessage({ id: "assignment" })}
              rules={validation("assignment", intl)}
            />
            <Input
              type="text"
              name="email"
              label={intl.formatMessage({ id: "email" })}
              placeholder={intl.formatMessage({ id: "email" })}
              rules={validation("email", intl)}
            />
            <Input
              type="text"
              name="address"
              label={intl.formatMessage({ id: "address" })}
              placeholder={intl.formatMessage({ id: "address" })}
              rules={validation("address", intl)}
            />
            <TelephoneInput
              name="telephone"
              placeholder={intl.formatMessage({
                id: "telephone",
              })}
              label={intl.formatMessage({
                id: "telephone",
              })}
              rules={validation("telephone", intl)}
              refForm={form}
              required={false}
            />
            {havePermission && (
              <>
                <SwitchContainer>
                  <Switch
                    onChange={(e) => handleGetNewEmployeeCategories(e)}
                    checked={isCreateCredential}
                  />
                  <p>
                    {intl.formatMessage({
                      id: "create_credential_collaborator_page",
                    })}
                  </p>
                </SwitchContainer>
                {isCreateCredential && (
                  <Select
                    name="category"
                    label={intl.formatMessage({ id: "credential_types" })}
                    showArrow
                    allowClear
                    placeholder={intl.formatMessage({ id: "credential_types" })}
                    rules={validation("category", intl)}
                    options={categories}
                  />
                )}
              </>
            )}

            <ButtonContainer>
              <Button
                label={intl.formatMessage({ id: "cancel" })}
                type="link"
                onClick={handleGoBack}
              />
              <Button
                label={intl.formatMessage({ id: "save" })}
                type="primary"
                htmlType="submit"
              />
            </ButtonContainer>
          </Form>
        </FormContainer>
      )}
    </Container>
  );
};

export default NewEmployee;
