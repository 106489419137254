import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../ConfirmationModal";
import FormButton from "../../FormButton";
import SelectWithLabel from "../../SelectWithLabel";
import {
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  InformationContainer,
  InformationNotification,
  AdditionalInformationContainer,
  InformationLabel,
  InformationValue,
  BoldInformationLabel,
  BoldInformationValue,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import api from "../../../service";
import Modal from "../../Modal";
import { useHistory } from "react-router-dom";

import { useLoading } from "../../../hooks/loading";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import * as Yup from "yup";
import { useToast } from "../../../hooks/toast";
import getValidationErrors from "../../../utils/getValidationErrors";
import axios from "axios";
import CheckboxInput from "../../CheckboxInput";

const EcommerceModal = ({
  setIsOpen,
  modalIsOpen = false,
}) => {
  const { user, token } = useSelector((state) => state.auth);
  const { isLoading, handleLoading } = useLoading();
  const { addToast } = useToast();
  const intl = useIntl();
  const history = useHistory();

  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedEventId, setSelectedEventId] = useState();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedCompanyEmail, setSelectedCompanyEmail] = useState();
  const [selectedCompanyRoleType, setSelectedCompanyRoleType] = useState();
  const [opportunities, setOpportunities] = useState();
  const [chlebaAccessInformation, setChlebaAccessInformation] = useState();
  const [types, setTypes] = useState([]);

  const [isConfirmed, setIsConfirmed] = useState(false);

  const checkboxOptions = useMemo(
    () => [
      {
        id: "chleba_authentication_confirmation",
        value: true,
        label: intl.formatMessage({
          id: "chleba_authentication_confirmation",
        }),
      },
    ],
    [intl]
  );

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    roleType: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    company: Yup.string()
        .nullable()
        .required(intl.formatMessage({ id: "error.field_required" })),
    opportunity: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  useEffect(() => {
    setTypes([
      {
        label: intl.formatMessage({ id: "exhibitor" }),
        value: "EXHIBITOR",
      },
      {
        label: intl.formatMessage({ id: "sponsor_without_m2" }),
        value: "SPONSOR_WITHOUT_M2",
      },
      {
        label: intl.formatMessage({ id: "service_provider" }),
        value: "SERVICE_PROVIDER",
      },
    ]);

    if (modalIsOpen) {
      handleLoading(true);
      setIsConfirmed(false);
      setChlebaAccessInformation();
      setSelectedCompany();
      setSelectedCompanyEmail();
      setSelectedCompanyRoleType();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .get(`/admin/events`, config)
        .then((result) => {
          const events = result.data.output.map((event) => ({
            label: event.eventEditionName,
            value: event.eventEditionCode,
            code: event.idNumber,
          }));
          let eventsSort = [...events].sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          setEvents(eventsSort);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  }, [
    addToast,
    intl,
    token,
    handleLoading,
    modalIsOpen,
  ]);

  useEffect(() => {
    if (selectedEvent && selectedCompanyRoleType) {
      handleLoading(true);
      formRef.current.setFieldValue("company");
      formRef.current.setFieldValue("opportunity");
      setIsConfirmed(false);
      api
        .get("/admin/account/ecommerce/event", {
          params: {
            event_code: selectedEvent,
            role_type: selectedCompanyRoleType,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const companies = result.data.users.map((user) => ({
            label: user.commercialName,
            value: user.id,
            email: user.email,
          }));
          setCompanies(companies);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    selectedEvent,
    addToast,
    intl,
    token,
    handleLoading,
    selectedCompanyRoleType,
  ]);

  useEffect(() => {
    if (selectedCompany) {
      api
        .patch(
          `/users/admin/chleba/access-information/`,
          {
            selectedUserId: selectedCompany,
            roleType: selectedCompanyRoleType,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) =>
          setChlebaAccessInformation(
            response.data.systemUserChlebaAccessInformationOutput
          )
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    } else {
      setChlebaAccessInformation();
    }
  }, [
    addToast,
    handleLoading,
    intl,
    selectedCompany,
    selectedCompanyRoleType,
    token,
  ]);

  useEffect(() => {
    if (selectedEvent
        && selectedCompanyRoleType
        && selectedCompany) {
      handleLoading(true);
      formRef.current.setFieldValue("opportunity");

      const requestHeader = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      api
        .patch(
          "/admin/opportunity/event-and-user",
          {
            eventEditionCode: selectedEvent,
            userId: selectedCompany,
            roleType: selectedCompanyRoleType,
          },
          requestHeader
        )
        .then((result) => setOpportunities(result.data.output))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    selectedEvent,
    addToast,
    intl,
    token,
    handleLoading,
    selectedCompany,
    selectedCompanyRoleType,
  ]);

  const handleInvalidData = () => {
    setIsOpen(false);
    addToast({
      type: "info",
      title: intl.formatMessage({
        id: "info",
      }),
      description: intl.formatMessage({
        id: "chleba_incorrect_data_confirmation",
      }),
    });
  };

  const handleEcommerceSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) return;

        handleLoading(true);

        api
          .post(
            "/admin/ecommerce/chleba/auth",
            {
              opportunityId: data.opportunity,
              companyId: data.company,
              eventId: selectedEventId,
              email: selectedCompanyEmail,
              roleType: selectedCompanyRoleType,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) =>
            window.open(
              result.data.ecommerceChlebaAuthenticationOutput.generatedUrl,
              "_blank"
            )
          )
          .catch((err) => {
            if (err.response.data.code === -18009
                || err.response.data.code === -18020
                || err.response.data.code === -18022
                || err.response.data.code === -18023) {
              addToast({
                type: "info",
                title: intl.formatMessage({
                  id: "info",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              });
            } else {
              addToast({
                type: "error",
                title: intl.formatMessage({
                  id: "error",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              });
            }
          })
          .finally(() => {
            setLoading(false);
            handleLoading(false);
          });
      } catch (err) {
        setLoading(false);
        handleLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [
      schema,
      loading,
      handleLoading,
      token,
      addToast,
      intl,
      selectedEventId,
      selectedCompanyEmail,
      selectedCompanyRoleType,
    ]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <>
      <Modal
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        modalWidth="50%"
        overflowX="auto"
      >
        <FormContainer>
          <Form ref={formRef} onSubmit={handleEcommerceSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="access_ecommerce" />
              </Title>
            </TitleContainer>
            <FieldsContainer>
              <SelectWithLabel
                name="event"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "event_edition" })}
                placeholder={intl.formatMessage({ id: "event_edition" })}
                onChange={(option) => {
                  setSelectedEvent(option?.value);
                  setSelectedEventId(option?.code);
                }}
                labelOrientation="vertical"
                options={events}
              />
              <SelectWithLabel
                name="roleType"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "type" })}
                placeholder={intl.formatMessage({ id: "type" })}
                labelOrientation="vertical"
                labelWidth="150px"
                options={types}
                onChange={(type) =>
                    setSelectedCompanyRoleType(type?.value)}
              />
              <SelectWithLabel
                name="company"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "company" })}
                placeholder={intl.formatMessage({ id: "company" })}
                onChange={(option) => {
                  setSelectedCompany(option?.value);
                  setSelectedCompanyEmail(option?.email);
                }}
                labelOrientation="vertical"
                options={companies}
              />
              <SelectWithLabel
                name="opportunity"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "contract" })}
                placeholder={intl.formatMessage({ id: "contract" })}
                labelOrientation="vertical"
                options={opportunities}
              />

              {chlebaAccessInformation && (
                <>
                  <AdditionalInformationContainer>
                    {selectedCompanyRoleType && (
                      <InformationContainer>
                        <InformationLabel>
                          <FormattedMessage id="type" />
                        </InformationLabel>
                        <InformationValue>
                          {intl.formatMessage({ id: selectedCompanyRoleType })}
                        </InformationValue>
                      </InformationContainer>
                    )}
                    <InformationContainer>
                      <InformationLabel>
                        <FormattedMessage id="document" />
                      </InformationLabel>
                      <InformationValue>
                        {chlebaAccessInformation.cnpj || "-"}
                      </InformationValue>
                    </InformationContainer>
                    <InformationContainer>
                      <InformationLabel>
                        <FormattedMessage id="telephone" />
                      </InformationLabel>
                      <InformationValue>
                        {chlebaAccessInformation.telephone || "-"}
                      </InformationValue>
                    </InformationContainer>
                    <InformationContainer>
                      <BoldInformationLabel>
                        <FormattedMessage id="taxes" />
                      </BoldInformationLabel>
                      <BoldInformationValue>
                        {chlebaAccessInformation.taxType || "-"}
                      </BoldInformationValue>
                    </InformationContainer>
                    <InformationContainer>
                      <BoldInformationLabel>
                        <FormattedMessage id="zip_code" />
                      </BoldInformationLabel>
                      <BoldInformationValue>
                        {chlebaAccessInformation.cep || "-"}
                      </BoldInformationValue>
                    </InformationContainer>
                    <InformationContainer>
                      <BoldInformationLabel>
                        <FormattedMessage id="address" />
                      </BoldInformationLabel>
                      <BoldInformationValue>
                        {chlebaAccessInformation.address || "-"}
                      </BoldInformationValue>
                    </InformationContainer>
                    <InformationContainer>
                      <InformationLabel>
                        <FormattedMessage id="district" />
                      </InformationLabel>
                      <InformationValue>
                        {chlebaAccessInformation.district || "-"}
                      </InformationValue>
                    </InformationContainer>
                    <InformationContainer>
                      <BoldInformationLabel>
                        <FormattedMessage id="city" />
                      </BoldInformationLabel>
                      <BoldInformationValue>
                        {chlebaAccessInformation.city || "-"}
                      </BoldInformationValue>
                    </InformationContainer>
                    <InformationContainer>
                      <BoldInformationLabel>
                        <FormattedMessage id="state" />
                      </BoldInformationLabel>
                      <BoldInformationValue>
                        {chlebaAccessInformation.state || "-"}
                      </BoldInformationValue>
                    </InformationContainer>
                    <InformationNotification>
                      <CheckboxInput
                        name="checkbox"
                        onChange={e => setIsConfirmed(e?.target?.checked)}
                        options={checkboxOptions}
                      />
                    </InformationNotification>
                  </AdditionalInformationContainer>
                  <Horizontal>
                    <FilterActionsContainer>
                      <SubmitContainer>
                        <FormButton
                          color="#C9C9C9"
                          type="button"
                          onClick={() => setIsOpen(false)}
                          loading={isLoading}
                        >
                          <FormattedMessage id="close" />
                        </FormButton>
                      </SubmitContainer>
                      <SubmitContainer>
                        <FormButton type="submit" loading={loading} disabled={!isConfirmed}>
                          <FormattedMessage id="yes" />
                        </FormButton>
                      </SubmitContainer>
                      <SubmitContainer>
                        <FormButton
                          loading={loading}
                          onClick={() => handleInvalidData()}
                        >
                          <FormattedMessage id="no" />
                        </FormButton>
                      </SubmitContainer>
                    </FilterActionsContainer>
                  </Horizontal>
                </>
              )}
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Modal>
    </>
  );
};

export default EcommerceModal;
