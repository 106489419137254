import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import {
  Container,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  SessionContainer,
  SessionIconContainer,
  SessionTitle,
  Session,
  SessionDivider,
  InformationEditionRow,
  InformationEditionContainer,
  GoBackContainer,
  NotesContainer,
  FormButtonContainer,
  BadgeContainer,
} from "./styles";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import { Form } from "@unform/web";
import InputWithLabel from "../../../components/InputWithLabel";
import { MdInfo, MdHome, MdPerson, MdAssignment, MdNote } from "react-icons/md";
import { useToast } from "../../../hooks/toast";
import Table from "../../../components/Table";
import TextareaWithLabel from "../../../components/TextareaWithLabel";
import Badge from "../../../components/Badge";
import FormButton from "../../../components/FormButton";
import { Strings } from "../../../utils/stringSanitize";
import { format } from "date-fns";
import HtmlContainer from "../../../components/HtmlContainer";
import { base64ToBlob, downloadBlob } from "../../../utils/file";
import { useLoading } from "../../../hooks/loading";
import ExpandableRow from "./ExpandableRow";
import { convertToDateOnly } from "../../../utils/date";
import SelectWithLabel from "../../../components/SelectWithLabel";
import opportunityStatusTypesConstants from "../../../constants/opportunityStatusTypes";
import clientTypesConstants from "../../../constants/clientTypes";

const Detail = () => {
  const { user, token } = useSelector((state) => state.auth);
  const { id } = useParams();

  const [key, setKey] = useState(null);
  const [specialConditions, setSpecialConditions] = useState();

  const [data, setData] = useState();

  const formRef = useRef(null);
  const formRefVipGuests = useRef(null);
  const intl = useIntl();
  const history = useHistory();

  const { addToast } = useToast();

  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [additionalProducts, setAdditionalProducts] = useState([]);
  const isAdministrator = user?.role?.type === "ADMINISTRATOR";
  const [boothList, setBoothList] = useState([]);
  const [selectedBoothNumber, setSelectedBoothNumber] = useState("");
  const [selectedBoothArea, setSelectedBoothArea] = useState("");
  const [selectedBoothType, setSelectedBoothType] = useState("");
  const opportunityStatusTypes = opportunityStatusTypesConstants();
  const clientTypes = clientTypesConstants();

  const schema = Yup.object().shape({
    event: Yup.string(),
    feira_ingles: Yup.string(),
    edicaoDaFeira: Yup.string(),
    year: Yup.string(),
    eventEditionNumber: Yup.string(),
    place: Yup.string(),
    pavilion: Yup.string(),
    address: Yup.string(),
    fair_email: Yup.string(),
    responsible: Yup.string(),
    video_link: Yup.string(),
    mounting_start: Yup.string().nullable(),
    mounting_end: Yup.string().nullable(),
    realization_start: Yup.string().nullable(),
    realization_end: Yup.string().nullable(),
    unmounting_start: Yup.string().nullable(),
    unmounting_end: Yup.string().nullable(),
    payments_start: Yup.string().nullable(),
    payments_end: Yup.string().nullable(),
    payments_limit_end: Yup.string().nullable(),
    appointment_configuration_start: Yup.string().nullable(),
    appointment_configuration_end: Yup.string().nullable(),
    appointment_configuration_opening: Yup.string(),
    appointment_configuration_closure: Yup.string(),
    interval_between_appointments: Yup.string(),
    credential_metrics_free_quota: Yup.string(),
    credential_metrics_considered_area: Yup.string(),
    contract_id: Yup.string(),
    notes: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    headband: Yup.string(),
  });

  const schemaVipGuests = Yup.object().shape({
    number_of_vip_guests: Yup.string()
      .required(intl.formatMessage({ id: "error.field_required" }))
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      ),
    event_vip_metric: Yup.string(),
  });

  const schemaHeadband = Yup.object().shape({
    headband: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "front_sign_error" })),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/admin/opportunity/${id}`,
            {
              observation: data.notes,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.opportunity",
              }),
            });
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, id, intl, loading, schema, token]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/admin/opportunity/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data.opportunity);
        setBoothList(response.data.opportunity.booths
            .filter((booth) => booth.boothNumber)
            .map((booth) => ({
                label: booth.boothNumber,
                value: booth.boothNumber,
            }))
            .sort((a, b) =>
                a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            )
        );
        setSpecialConditions(response.data.opportunity.specialConditions);
        setKey(new Date());
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, handleLoading, id, intl, token]);

  useEffect(() => {
    if (data) {
      setLoading(true);
      handleLoading(true);
      api
        .patch(
          "/ecommerce/chleba/order",
          {
            contractNumber: data.contractNumber,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) =>
          setAdditionalProducts(response.data.ecommerceChlebaOrderFilterOutput)
        )
        .catch((err) => {
          setLoading(false);
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
          setLoading(false);
        });
    }
  }, [addToast, handleLoading, intl, token, data]);

  const handleExportGuests = () => {
    if (!isAdministrator) {
      handleLoading(true);
      api
        .patch(
          `/admin/manage-guests/export`,
          {
            eventEditionCode: data.eventEditionCode,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          const { file, name } = result.data.manageGuestAdminExportOutput;
          var blob = base64ToBlob(file, "application/vnd.ms-excel");
          downloadBlob(blob, `${name}.xlsx`);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  };

  const handleVipGuestsSubmit = useCallback(
    async (data) => {
      try {
        formRefVipGuests.current?.setErrors({});
        await schemaVipGuests.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        api
          .put(
            `/admin/opportunity/vip/${id}`,
            {
              numberOfVipGuests: data.number_of_vip_guests,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.opportunity",
              }),
            });
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefVipGuests.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, id, intl, loading, schemaVipGuests, token]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const validationVipGuestsBlur = (name) => {
    let errors = formRefVipGuests.current.getErrors();
    validateSingleFieldOnBlur(errors, schemaVipGuests, name, formRefVipGuests);
  };

  const validationBlurHeadband = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schemaHeadband, name, formRef);
  };

  const handleBoothVisualization = () => {
    const boothNumber = formRef.current.getFieldValue("booth_select");
    if (boothNumber === "") {
      return;
    }

    let boothFiltered = data.booths.filter(
      (booth) => booth.boothNumber === boothNumber
    )[0];

    setSelectedBoothNumber(boothFiltered?.boothNumber);
    setSelectedBoothArea(boothFiltered?.area);
    setSelectedBoothType(boothFiltered?.type);
  };

  const mandatoryServicesColumns = [
    {
      name: intl.formatMessage({ id: "type" }),
      selector: (row) => row.eventProductType,
    },
    {
      name: intl.formatMessage({ id: "product_name" }),
      selector: (row) => row.productLocalName,
      format: (row) => row.productLocalName,
    },
    {
      name: intl.formatMessage({ id: "quantity" }),
      selector: (row) => row.quantity,
    },
    {
      name: intl.formatMessage({ id: "total_value" }),
      selector: (row) => row.totalPrice,
    },
  ];

  const optionalServiceColumns = [
    {
      name: intl.formatMessage({ id: "order_number" }),
      selector: (row) => row.orderId,
    },
    {
      name: intl.formatMessage({ id: "situation" }),
      selector: (row) => row.paid,
      format: (row) =>
        row.paid
          ? intl.formatMessage(
              { id: "paid_on" },
              { value: convertToDateOnly(row.paymentDate) }
            )
          : row.statusDesc,
    },
    {
      name: intl.formatMessage({ id: "sale_date" }),
      selector: (row) => row.saleDate,
      format: (row) => convertToDateOnly(row.saleDate),
    },
    {
      name: intl.formatMessage({ id: "value" }),
      selector: (row) => row.value,
      format: (row) => `${row.value.toFixed(2)} ${row.currency || ""}`,
    },
    {
      name: intl.formatMessage({ id: "type" }),
      selector: (row) => row.roleType,
      format: (row) => intl.formatMessage({ id: row.roleType }),
    },
    {
      name: intl.formatMessage({ id: "name" }),
      selector: (row) => row.clientName,
      format: (row) => row.clientName || "",
    },
  ];

  const saveHeadbandOpportunity = useCallback(
    (contractId, headband) => {
      try {
        setLoading(true);
        if (loading) {
          return;
        }

        api
          .post(
            `/admin/opportunity/headband`,
            {
              opportunityId: contractId,
              headband: headband,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.front_sign_opportunity_creation",
              }),
            });
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, token]
  );

  const saveHeadband = () => {
    validationBlurHeadband("headband");
    const headband = formRef.current.getFieldValue("headband");
    const contractId = data.opportunityId;
    if (headband == null || headband === "") {
      return;
    }
    saveHeadbandOpportunity(contractId, headband);
  };

  const validatePrevPath = () => {
    if (typeof history.location.state == "undefined") {
      return false;
    } else {
      if (history.location.state.prevPath.pathname.includes("/account/")) {
        return true;
      }
    }
  };

  return (
    <>
      <AnimatedMainContainer
        breadcrumb={intl.formatMessage({
          id: "breadcrumb.contract_information",
        })}
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          key={key}
          initialData={{
            event: data?.eventEditionName,
            prefix: data?.eventEditionCode,
            address: data?.eventVenue,
            year: data?.eventStartDate
              ? new Date(data?.eventStartDate).getUTCFullYear()
              : "",
            pavilion: boothList[0]?.pavilion,
            fair_email: data?.opportunityContact?.email,
            responsible:
              data?.operationsContact &&
              Strings.fullname(
                data?.operationsContact?.firstName,
                data?.operationsContact?.lastName
              ),
            status: opportunityStatusTypes.filter((pt) =>
              pt.value === data?.status)[0]?.label,
            company_name: data?.account?.accountName,
            commercial_name: data?.tradeName,
            fiscal_email: data?.billingContact?.email,
            operational_email: data?.operationsContact?.email,
            marketing_email: data?.marketingContact?.email,
            cnpj: data?.account?.accountGovCompanyCode,
            telephone: data?.account?.phone,
            invoice_responsible:
              data?.billingContact &&
              Strings.fullname(
                data?.billingContact?.firstName,
                data?.billingContact?.lastName
              ),
            operational_responsible:
              data?.operationsContact &&
              Strings.fullname(
                data?.operationsContact?.firstName,
                data?.operationsContact?.lastName
              ),
            contract_number: data?.contractNumber,
            update:
              data?.lastModifiedDate &&
              format(new Date(data?.lastModifiedDate), "dd/MM/yyyy"),
            creation:
              data?.createdAt &&
              format(new Date(data?.createdAt), "dd/MM/yyyy"),
            responsible_for_the_contract:
              data?.opportunityContact &&
              Strings.fullname(
                data?.opportunityContact?.firstName,
                data?.opportunityContact?.lastName
              ),
            responsible_invoice:
              data?.billingContact &&
              Strings.fullname(
                data?.billingContact?.firstName,
                data?.billingContact?.lastName
              ),
            contract_type: data?.type,
            client_type: clientTypes.filter((pt) =>
              pt.value === data?.contractType)[0]?.label,
            user_type: data?.actorType,
            parent_opportunity: data?.parentOpportunity,
            currency: data?.contractCurrency,
            contract_value: data?.amount,
            commercial_contact:
              data?.opportunityContact &&
              Strings.fullname(
                data?.opportunityContact?.firstName,
                data?.opportunityContact?.lastName
              ),
            paid_by: data?.paidBy,
            notes: data?.observation,
            special_conditions: data?.specialConditions,
            headband: data?.headband,
          }}
        >
          <Container>
            <TitleContainer>
              <Title>
                <FormattedMessage id="contract_visualization" />
              </Title>
              <GoBackContainer>
                <BadgeContainer
                  onClick={() =>
                    validatePrevPath()
                      ? history.goBack()
                      : history.push("/contracts", {
                          prevPath: history.location,
                        })
                  }
                >
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </GoBackContainer>
            </TitleContainer>
            <SessionDivider nr_items="2">
              <Session>
                <SessionContainer>
                  <SessionIconContainer>
                    <MdInfo size="16px" color="#002244" />
                  </SessionIconContainer>
                  <SessionTitle>
                    <FormattedMessage id="event_edition_detail" />
                  </SessionTitle>
                </SessionContainer>
                <FieldsContainer>
                  <InputWithLabel
                    name="event"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "event_edition" })}
                  />

                  <InformationEditionContainer>
                    <InformationEditionRow>
                      <InputWithLabel
                        name="prefix"
                        validationBlur={validationBlur}
                        disabled
                        label={intl.formatMessage({ id: "prefix" })}
                      />
                      <InputWithLabel
                        name="year"
                        validationBlur={validationBlur}
                        disabled
                        label={intl.formatMessage({ id: "year" })}
                      />
                    </InformationEditionRow>
                  </InformationEditionContainer>

                  <InputWithLabel
                    name="pavilion"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "pavilion" })}
                  />
                  <InputWithLabel
                    name="address"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "address" })}
                  />
                  <InputWithLabel
                    name="fair_email"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "responsible_email" })}
                  />

                  <Horizontal>
                    <InputWithLabel
                      name="responsible"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "responsible" })}
                    />
                    <InputWithLabel
                      name="status"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "opportunity_status" })}
                    />
                  </Horizontal>
                </FieldsContainer>
              </Session>
              <Session>
                <SessionContainer>
                  <SessionIconContainer>
                    <MdPerson size="16px" color="#002244" />
                  </SessionIconContainer>
                  <SessionTitle>
                    <FormattedMessage id="customer_detail" />
                  </SessionTitle>
                </SessionContainer>
                <FieldsContainer>
                  <InputWithLabel
                    name="company_name"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "company_name" })}
                  />
                  <InputWithLabel
                    name="commercial_name"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "commercial_name" })}
                  />
                  <InputWithLabel
                    name="fiscal_email"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "fiscal_email" })}
                  />
                  <InputWithLabel
                    name="operational_email"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "operational_email" })}
                  />
                  <InputWithLabel
                    name="marketing_email"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "marketing_email" })}
                  />
                  <Horizontal>
                    <InputWithLabel
                      name="cnpj"
                      validationBlur={validationBlur}
                      disabled
                      label="CNPJ"
                    />
                    <InputWithLabel
                      name="telephone"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "telephone" })}
                    />
                  </Horizontal>
                  <Horizontal>
                    <InputWithLabel
                      name="responsible_invoice"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({
                        id: "responsible_for_the_invoice",
                      })}
                    />
                    <InputWithLabel
                      name="operational_responsible"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({
                        id: "operational_responsible",
                      })}
                    />
                  </Horizontal>
                </FieldsContainer>
              </Session>
            </SessionDivider>
            <SessionDivider nr_items="2">
              <Session>
                <SessionContainer>
                  <SessionIconContainer>
                    <MdAssignment size="16px" color="#002244" />
                  </SessionIconContainer>
                  <SessionTitle>
                    <FormattedMessage id="contract" />
                  </SessionTitle>
                </SessionContainer>
                <FieldsContainer>
                  <InformationEditionContainer>
                    <InformationEditionRow>
                      <InputWithLabel
                        name="contract_number"
                        validationBlur={validationBlur}
                        disabled
                        className="edition"
                        label={intl.formatMessage({ id: "contract_number" })}
                      />
                      <InputWithLabel
                        name="creation"
                        validationBlur={validationBlur}
                        disabled
                        label={intl.formatMessage({ id: "creation" })}
                      />
                      <InputWithLabel
                        name="update"
                        validationBlur={validationBlur}
                        disabled
                        label={intl.formatMessage({ id: "update" })}
                      />
                    </InformationEditionRow>
                  </InformationEditionContainer>
                  <Horizontal>
                    <InputWithLabel
                      name="client_type"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({
                        id: "client_type",
                      })}
                    />
                    <InputWithLabel
                      name="user_type"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "user_type" })}
                    />
                  </Horizontal>
                  <InputWithLabel
                    name="parent_opportunity"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({
                      id: "parent_opportunity",
                    })}
                  />
                  <Horizontal>
                    <InputWithLabel
                      name="contract_type"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({
                        id: "contract_type",
                      })}
                    />
                    <InputWithLabel
                      name="currency"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({
                        id: "currency",
                      })}
                    />

                    <InputWithLabel
                      name="contract_value"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "contract_value" })}
                    />
                  </Horizontal>
                  <HtmlContainer
                    value={specialConditions}
                    label={intl.formatMessage({ id: "special_conditions" })}
                    className="margin_bottom"
                  />
                  <InputWithLabel
                    name="paid_by"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "paid_by" })}
                  />
                  <Form
                    ref={formRefVipGuests}
                    onSubmit={handleVipGuestsSubmit}
                    key={key}
                    initialData={{
                      number_of_vip_guests: data?.numberOfVipGuests,
                      event_vip_metric: data?.eventVipMetric,
                    }}
                  >
                    <InputWithLabel
                      name="event_vip_metric"
                      disabled
                      label={intl.formatMessage({
                        id: "vip_default_setting_event",
                      })}
                    />
                    <InputWithLabel
                      name="number_of_vip_guests"
                      validationBlur={validationVipGuestsBlur}
                      label={intl.formatMessage({
                        id: "vip_manual_setting_opportunity",
                      })}
                      required
                    />
                    <Horizontal>
                      <div>
                        <FormButton
                          type="button"
                          fontSize="12px"
                          onClick={() => {
                            formRefVipGuests.current.submitForm();
                          }}
                          loading={loading}
                        >
                          <FormattedMessage id="edit_quantity" />
                        </FormButton>
                      </div>
                      {!isAdministrator && (
                        <div>
                          <FormButton
                            type="button"
                            fontSize="12px"
                            onClick={() => handleExportGuests()}
                            loading={loading}
                          >
                            <FormattedMessage id="export_xls" />
                          </FormButton>
                        </div>
                      )}
                    </Horizontal>
                  </Form>
                </FieldsContainer>
              </Session>
              <Session>
                <SessionContainer>
                  <SessionIconContainer>
                    <MdHome size="16px" color="#002244" />
                  </SessionIconContainer>
                  <SessionTitle>
                    <FormattedMessage id="booth" />
                  </SessionTitle>
                </SessionContainer>
                <FieldsContainer>
                  <Horizontal>
                    <SelectWithLabel
                      name="booth_select"
                      label={intl.formatMessage({
                        id: "select_a_booth",
                      })}
                      placeholder={intl.formatMessage({
                        id: "select_an_option",
                      })}
                      options={boothList}
                    />
                    <FormButtonContainer>
                      <FormButton
                        type="button"
                        loading={loading}
                        onClick={handleBoothVisualization}
                      >
                        <FormattedMessage id="visualize" />
                      </FormButton>
                    </FormButtonContainer>
                  </Horizontal>
                  <Horizontal>
                    <InputWithLabel
                      name="booth_number"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "booth_number" })}
                      value={selectedBoothNumber}
                    />
                    <InputWithLabel
                      name="area"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "area" })}
                      value={selectedBoothArea}
                    />
                  </Horizontal>
                  <Horizontal>
                    <InputWithLabel
                      name="booth_type"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "booth_type" })}
                      value={selectedBoothType}
                    />
                  </Horizontal>
                  <Horizontal>
                    <InputWithLabel
                      name="headband"
                      validationBlur={validationBlurHeadband}
                      label={intl.formatMessage({ id: "front_sign" })}
                      maxLength="500"
                      required
                    />
                    <FormButtonContainer>
                      <FormButton
                        type="button"
                        loading={loading}
                        onClick={saveHeadband}
                      >
                        <FormattedMessage id="save" />
                      </FormButton>
                    </FormButtonContainer>
                  </Horizontal>
                </FieldsContainer>
                <NotesContainer>
                  <SessionContainer>
                    <SessionIconContainer>
                      <MdNote size="16px" color="#002244" />
                    </SessionIconContainer>
                    <SessionTitle>
                      <FormattedMessage id="notes" />
                    </SessionTitle>
                  </SessionContainer>
                  <FieldsContainer>
                    <TextareaWithLabel
                      name="notes"
                      className="margin_bottom"
                      validationBlur={validationBlur}
                      isHtml={true}
                      required
                    />
                    <Horizontal>
                      <div>
                        <FormButton
                          fontSize="12px"
                          type="submit"
                          loading={loading}
                        >
                          <FormattedMessage id="save" />
                        </FormButton>
                      </div>
                    </Horizontal>
                  </FieldsContainer>
                </NotesContainer>
              </Session>
            </SessionDivider>
            <Table
              className="mandatory-table"
              data={data?.mandatoryProducts}
              columns={mandatoryServicesColumns}
              title={intl.formatMessage({
                id: "mandatory_products_and_services",
              })}
              titleMarginLeft="0px"
              progressPending={loading}
              hasPagination={false}
            />
            <Table
              className="mandatory-table"
              data={additionalProducts}
              columns={optionalServiceColumns}
              title={intl.formatMessage({
                id: "optional_products_and_services",
              })}
              titleMarginLeft="0px"
              progressPending={loading}
              hasPagination={false}
              expandableRows
              expandableRowsComponent={ExpandableRow}
            />
          </Container>
        </Form>
      </AnimatedMainContainer>
    </>
  );
};

export default Detail;
