export function getNewEmployeeCategories({callbackError, selectedEvent, selectedContract, isPortuguese}) {
  return {
    type: "GET_NEW_EMPLOYEE_CATEGORIES",
    payload: {
      selectedEvent,
      selectedContract,
      callbackError,
      isPortuguese
    },
  };
}

export function getCollaborators({callBackError, page, perPage, search}){
  return {
    type: "GET_COLLABORATORS",
    payload: {
      page,
      perPage,
      callBackError,
      search
    },
  }
}

export function getSingleCollaborator({callBackError, callBackSuccess, id}){
  return {
    type: "GET_SINGLE_COLLABORATOR",
    payload: {
      id,
      callBackError,
      callBackSuccess
    },
  }
}

export function setCollaboratorsSuccess(collaboratorsInfo){
  return {
    type: "SET_COLLABORATORS",
    payload: {
      collaboratorsInfo,
    },
  }
}

export function setSingleCollaboratorSuccess(collaborator){
  return {
    type: "SET_SINGLE_COLLABORATOR",
    payload: {
      collaborator,
    },
  }
}

export function setNewEmployeeCategoriesSuccess(categories) {
  return {
    type: "SET_NEW_EMPLOYEE_CATEGORIES",
    payload: {
      categories,
    },
  };
}

export function postNewEmployee({employee, callBackError, callBackSuccess}){
  return {
    type: "POST_NEW_EMPLOYEE",
    payload: {
      employee,
      callBackError,
      callBackSuccess
    },
  };
}

export function editEmployee({employee, callBackError, callBackSuccess, id}){
  return {
    type: "EDIT_EMPLOYEE",
    payload: {
      employee,
      callBackError,
      callBackSuccess,
      id
    },
  };
} 

export function deleteEmployee({callBackError, callBackSuccess, id}){
  return {
    type: "DELETE_EMPLOYEE",
    payload: {
      callBackError,
      callBackSuccess,
      id
    },
  };
} 



export function clearEmployee() {
  return {
    type: "CLEAR_EMPLOYEE",
  };
}