import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import InputWithLabel from "../../components/InputWithLabel";
import MaskedInputWithLabel from "../../components/MaskedInputWithLabel";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  FilterEditionContainer,
  SubmitContainer,
  FilterSubmitContainer,
  BadgeContainer,
  CredentialsActionContainer,
  GoBackContainer,
  ActionContainer,
  AdditionalInformationContainer,
  InformationContainer,
  InformationLabel,
  InformationValue,
  AssociateContainer,
  AssociateActionsContainer
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import Loader from "react-loader-spinner";
import { FaExclamation } from "react-icons/fa";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import axios from "axios";
import Modal from "../../components/Modal";
import InputWithHorizontalLabel from "../../components/InputWithHorizontalLabel";
import useGenericDocumentMask from "../../utils/useGenericDocumentMask";
import ConfirmationModal from "../../components/ConfirmationModal";
import FileSaver from "file-saver";

const Credentials = () => {
  const { user, token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedContract, setSelectedContract] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedAllFilters, setSelectedAllFilters] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const { isLoading, handleLoading } = useLoading();
  const editFormRef = useRef(null);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [editData, setEditData] = useState([]);
  const [key, setKey] = useState();
  const [editOpportunities, setEditOpportunities] = useState([]);
  const [editCollaborators, setEditCollaborators] = useState([]);
  const [editCategories, setEditCategories] = useState([]);
  const [editSelectedOpportunity, setEditSelectedOpportunity] = useState();
  const [editSelectedCollaborator, setEditSelectedCollaborator] = useState();
  const [editOpportunityItem, setEditOpportunityItem] = useState();
  const [editCollaboratorItem, setEditCollaboratorItem] = useState();
  const [editCategoryItem, setEditCategoryItem] = useState();
  const [types, setTypes] = useState([]);
  const [statusBasedValues, setStatusBasedValues] = useState([]);
  const [documentMask, setDocumentMask] = useGenericDocumentMask();
  const [selectedCollaboratorsWithoutLastNameOrEmail, setSelectedCollaboratorsWithoutLastNameOrEmail] =
    useState([]);
  const [collaboratorWithoutLastNameOrEmailModalOpen, setCollaboratorWithoutLastNameOrEmailModalOpen] =
    useState(false);
  const [searchCanBePreserved, setSearchCanBePreserved] = useState(true);
  const isPortuguese = useMemo(()=> intl.locale === 'pt', [intl]);

  const isExhibitor = user?.role?.type === "EXHIBITOR";
  const isSponsor = user?.role?.type === "SPONSOR_WITHOUT_M2";

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    contract: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    category: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    collaborator: Yup.array().min(
      1,
      intl.formatMessage({ id: "select_at_least_one_of_the_options" })
    ),
    name: Yup.string().nullable(),
    document: Yup.string().nullable(),
    is_printed: Yup.bool().nullable(),
    company_name: Yup.string().nullable(),
  });

  const editSchema = Yup.object().shape({
    edit_opportunity: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    edit_collaborator: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    edit_category: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    edit_assignment: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    edit_credential_name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    edit_email: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  const handleUpdateCategoriesAndCollaborators = useCallback(() => {
    handleLoading(true);
    const eventId = formRef.current.getFieldValue("event");
    const opportunityId = formRef.current.getFieldValue("contract");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .all([
        api.patch(
          `/accreditation/quota/`,
          { eventId: eventId, opportunityId: opportunityId },
          config
        ),
        api.get(
          `/ecommerce/category/available/opportunity/${opportunityId}`,
          config
        ),
        api.get(
          `/associate-collaborators/by-user?opportunity=${opportunityId}&credential=0`,
          config
        ),
      ])
      .then(
        axios.spread(
          (quotaResponse, categoryResponse, collaboratorsResponse) => {
            const { total, used } = quotaResponse.data.credentialQuotesOutput;
            const credentialCategories = handleCategoryReduce(
              categoryResponse.data
                .ecommerceChlebaCategoryCredentialAvailableOutputs,
              total,
              used,
              user?.role?.type
            );
            setTypes(credentialCategories);
            setCollaborators(collaboratorsResponse.data.output);
          }
        )
      )
      .catch((error) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + error.response.data.code,
          }),
        })
      )
      .finally(handleLoading(false));
  }, [addToast, handleLoading, intl, token]);

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("name", "");
    formRef.current.setFieldValue("document", "");
    formRef.current.setFieldValue("is_printed", {
      label: intl.formatMessage({ id: "all" }),
      value: null,
    });
    formRef.current.setFieldValue("company_name", "");
    formRef.current.setFieldValue("collaborator");
    formRef.current.setFieldValue("category");
    setFiltered(false);
    handleUpdateCategoriesAndCollaborators();
  };

  const handleCategoryReduce = (categories, total, used, userRole) => {
    return categories.reduce(
      (categories, category) => [
        ...categories,
        {
          value: category.id,
          label: isPortuguese ? category.title : category.englishTitle,
          information: {
            total: category.total || 0,
            used: category.used || 0,
          },
        },
      ],
      userRole === "EXHIBITOR"
        ? [
            {
              label:
                intl.formatMessage({ id: "contract" }) +
                " - " +
                (used || "0") +
                "/" +
                (total || "0"),
              value: 0,
              information: {
                total: total || 0,
                used: used || 0,
              },
            },
          ]
        : []
    );
  };

  const handleSearchForAssociatedCredentials = useCallback(() => {
    try {
      if (selectedAllFilters) {
        setLoading(true);
        if (loading) {
          return;
        }

        api
          .patch(
            `/accreditation`,
            {
              eventId: formRef.current.getFieldValue("event"),
              opportunityId: formRef.current.getFieldValue("contract"),
              categoryId: formRef.current.getFieldValue("category"),
              associateCollaboratorName: formRef.current.getFieldValue("name"),
              associateCollaboratorDocument:
                formRef.current.getFieldValue("document"),
              isPrinted: formRef.current.getFieldValue("is_printed"),
              companyName: formRef.current.getFieldValue("company_name"),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setFiltered(true);
            setData(result.data.credentialOutputList);
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }
    }
  }, [addToast, intl, token, loading, selectedAllFilters]);

  const filterSelectedCollaboratorsWithoutLastNameOrEmail = (collaboratorIdList) => {
    const filteredCollaborators = [];

    collaboratorIdList.forEach((collaboratorId) => {
      let selectedCollaborator = collaborators.find(
        (collaborator) => collaborator.value === collaboratorId
      );

      if (selectedCollaborator
          && (!selectedCollaborator.email
              || !selectedCollaborator.lastName)) {
        filteredCollaborators.push(selectedCollaborator);
      }
    });

    setSelectedCollaboratorsWithoutLastNameOrEmail(filteredCollaborators);
    return filteredCollaborators;
  };

  const generateCollaboratorsWithoutLastNameOrEmailTxtText = () => {
    let data = intl.formatMessage({
      id: "collaborators_without_last_name_email_file_message",
    }) + "\n\n";

    let collaboratorLabelList =
      selectedCollaboratorsWithoutLastNameOrEmail.map(
        (collaborator) => collaborator.label
      );

    return data + collaboratorLabelList.join('\n');
  };

  const downloadCollaboratorsWithoutLastNameOrEmailTxt = () => {
    setCollaboratorWithoutLastNameOrEmailModalOpen(false);
    const collaboratorsTxtData = new Blob(
      [generateCollaboratorsWithoutLastNameOrEmailTxtText()], { type: "text/plain" }
    );
    FileSaver.saveAs(collaboratorsTxtData, "collaborators_without_last_name_and_email.txt");
  };

  const handleAssociateSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        const filteredCollaboratorsWithoutLastNameOrEmail =
          filterSelectedCollaboratorsWithoutLastNameOrEmail(data.collaborator);

        if ((isExhibitor || isSponsor)
            && filteredCollaboratorsWithoutLastNameOrEmail.length > 0) {
          setCollaboratorWithoutLastNameOrEmailModalOpen(true);
          setLoading(false);
        } else {
          api
            .post(
              `/accreditation`,
              {
                eventId: data.event,
                opportunityId: data.contract,
                associateCollaboratorList: data.collaborator,
                categoryId: data.category,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "*/*",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(() => {
              if (data.collaborator.length === 1)
                addToast({
                  type: "success",
                  title: intl.formatMessage({
                    id: "success",
                  }),
                  description: intl.formatMessage({
                    id: "success.credential_associated",
                  }),
                });
              else 
                addToast({
                  type: "success",
                  title: intl.formatMessage({
                    id: "success",
                  }),
                  description: intl.formatMessage({
                    id: "success.credentials_associated",
                  }),
                });
              clearFields();
            })
            .catch((err) =>
              addToast({
                type: "error",
                title: intl.formatMessage({
                  id: "error",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              })
            )
            .finally(() => setLoading(false));
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, token, schema, loading]
  );

  useEffect(() => {
    handleLoading(true);
    setStatusBasedValues([
      {
        label: intl.formatMessage({ id: "all" }),
        value: null,
      },
      {
        label: intl.formatMessage({ id: "yes" }),
        value: true,
      },
      {
        label: intl.formatMessage({ id: "no" }),
        value: false,
      },
    ]);

    api
      .get(`/exhibitor/events/by-user`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output;
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    if (selectedEvent) {
      handleLoading(true);
      api
        .get(`/exhibitor/opportunity/event/${selectedEvent}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) =>
          setContracts(
            [...result.data.output].sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            )
          )
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [selectedEvent, addToast, intl, token, handleLoading]);

  useEffect(() => {
    formRef.current.setFieldValue("category");
    formRef.current.setFieldValue("collaborator");
    if (selectedEvent && selectedContract) {
      handleUpdateCategoriesAndCollaborators();
    }
  }, [selectedContract, selectedEvent]);

  useEffect(() => {
    if (selectedEvent && selectedContract && selectedCategory !== undefined) {
      setSelectedAllFilters(true);
    } else {
      setSelectedAllFilters(false);
      setFiltered(false);
    }
  }, [selectedEvent, selectedCategory, selectedContract]);

  const handleUnassociation = useCallback(
    (id) => {
      handleLoading(true);
      api
        .delete(`/accreditation/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.credential_unassociated",
            }),
          });
          clearFields();
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    },
    [addToast, intl, token, handleLoading]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleEdition = useCallback(
    (id) => {
      handleLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .all([api.get(`/accreditation/${id}`, config)])
        .then(
          axios.spread(async (accreditationResponse) => {
            const data = accreditationResponse.data.credentialOutput;
            setEditData(data);

            const {
              eventId,
              associateCollaboratorId,
              opportunityId,
              categoryId,
              userRole,
            } = accreditationResponse.data.credentialOutput;

            return {
              categoryId,
              opportunityId,
              eventId,
              associateCollaboratorId,
              userRole,
              opportunityResponse: await api.get(
                `/exhibitor/opportunity/event/${eventId}`,
                config
              ),
              collaboratorsResponse: await api.get(
                `/associate-collaborators/by-user?opportunity=${opportunityId}&credential=${id}`,
                config
              ),
            };
          })
        )
        .then(
          async ({
            categoryId,
            opportunityId,
            eventId,
            associateCollaboratorId,
            userRole,
            opportunityResponse,
            collaboratorsResponse,
          }) => {
            const opportunities = [...opportunityResponse.data.output].sort(
              (a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0)
            );
            setEditOpportunities(opportunities);
            handleOpportunityItemSelection(opportunities, opportunityId);
            const collaborators = collaboratorsResponse.data.output;
            setEditCollaborators(collaborators);
            handleCollaboratorItemSelection(
              collaborators,
              associateCollaboratorId
            );

            return {
              categoryId,
              userRole,
              quotaResponse: await api.patch(
                `/accreditation/quota/`,
                { eventId, opportunityId },
                config
              ),
              categoryResponse: await api.get(
                `/ecommerce/category/available/opportunity/${opportunityId}`,
                config
              ),
            };
          }
        )
        .then(({ categoryId, userRole, quotaResponse, categoryResponse }) => {
          const { total, used } = quotaResponse.data.credentialQuotesOutput;
          const credentialCategories = handleCategoryReduce(
            categoryResponse.data
              .ecommerceChlebaCategoryCredentialAvailableOutputs,
            total,
            used,
            userRole
          );
          setEditCategories(credentialCategories);
          handleCategoryItemSelection(credentialCategories, categoryId);
          setEditModalIsOpen(true);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
          setEditModalIsOpen(false);
        })
        .finally(() => {
          handleLoading(false);
          setKey(new Date());
          setLoading(false);
        });
    },
    [addToast, intl, token, handleLoading]
  );

  const handleOpportunityItemSelection = (opportunities, id) => {
    const item = opportunities.find((opportunity) => opportunity.value === id);
    setEditOpportunityItem(item);
    setEditSelectedOpportunity(id);
  };

  const handleCategoryItemSelection = (categories, id) => {
    const item = categories.find((category) => category.value === id);
    setEditCategoryItem(item);
  };

  const handleCollaboratorItemSelection = (collaborators, id) => {
    const item = collaborators.find(
      (collaborator) => collaborator.value === id
    );
    setEditCollaboratorItem(item);
    setEditSelectedCollaborator(item);
  };

  useEffect(() => {
    if (editModalIsOpen && editSelectedCollaborator) {
      editFormRef.current.setFieldValue(
        "edit_assignment",
        editSelectedCollaborator.jobTitle
      );
      editFormRef.current.setFieldValue(
        "edit_credential_name",
        editSelectedCollaborator.credentialName
      );
      editFormRef.current.setFieldValue(
        "edit_email",
        editSelectedCollaborator.email
      );
    }
  }, [editSelectedCollaborator]);

  useEffect(() => {
    if (editModalIsOpen && !loading) {
      editFormRef.current.setFieldValue("edit_category");
      editFormRef.current.setFieldValue("edit_collaborator");
      editFormRef.current.setFieldValue("edit_assignment", "");
      editFormRef.current.setFieldValue("edit_credential_name", "");
      editFormRef.current.setFieldValue("edit_email", "");
      if (editData && editSelectedOpportunity) {
        handleLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        };
        axios
          .all([
            api.patch(
              `/accreditation/quota/`,
              {
                eventId: editData.eventId,
                opportunityId: editSelectedOpportunity,
              },
              config
            ),
            api.get(
              `/ecommerce/category/available/opportunity/${editSelectedOpportunity}`,
              config
            ),
            api.get(
              `/associate-collaborators/by-user?opportunity=${editSelectedOpportunity}&credential=${editData.id}`,
              config
            ),
          ])
          .then(
            axios.spread(
              (quotaResponse, categoryResponse, collaboratorsResponse) => {
                const { total, used } =
                  quotaResponse.data.credentialQuotesOutput;
                const credentialCategories = handleCategoryReduce(
                  categoryResponse.data
                    .ecommerceChlebaCategoryCredentialAvailableOutputs,
                  total,
                  used,
                  editData.userRole
                );
                setEditCategories(credentialCategories);
                setEditCollaborators(collaboratorsResponse.data.output);
              }
            )
          )
          .catch((error) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + error.response.data.code,
              }),
            })
          )
          .finally(() => handleLoading(false));
      }
    }
    //loading shouldn't be in the dependency array
  }, [addToast, handleLoading, intl, editSelectedOpportunity, editData, token]);

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "associated_credential" }),
        selector: (row) => row.collaboratorName,
        sortable: true,
        allowOverflow: true,
        wrap: false,
        minWidth: "115px",
        cell: (row) => (
          <div
            title={`${
              !row.collaboratorStatus
                ? intl.formatMessage({ id: "collaborator_inactive" })
                : ""
            }`}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {!row.collaboratorStatus ? (
              <div>
                <FaExclamation color="#cc0000" />
              </div>
            ) : null}
            <div>{row.collaboratorName}</div>
          </div>
        ),
      },
      {
        name: intl.formatMessage({ id: "assignment" }),
        selector: (row) => row.jobTitle,
      },
      {
        name: intl.formatMessage({ id: "type_of_credential" }),
        selector: (row) => row.category,
        cell: (row) => row.category || intl.formatMessage({ id: "contract" }),
      },
      {
        name: intl.formatMessage({ id: "credential_name" }),
        selector: (row) => row.credentialName,
      },
      {
        name: intl.formatMessage({ id: "company_name_credential" }),
        selector: (row) => row.companyName,
      },
      {
        name: intl.formatMessage({ id: "printed_credential" }),
        cell: (row) =>
          row.printed
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
      {
        name: intl.formatMessage({ id: "scheduled" }),
        selector: (row) => row.scheduled,
        cell: (row) =>
          row.credentialScheduleId
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
      {
        cell: (row) => {
          return row.collaboratorStatus && !row.credentialScheduleId ? (
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "edit" })}
              onClick={() => handleEdition(row.id)}
            />
          ) : (
            <div></div>
          );
        },
        right: true,
        width: "125px",
      },
      {
        cell: (row) => {
          return !row.printed ? (
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "unassociate" })}
              onClick={() => handleUnassociation(row.id)}
            />
          ) : (
            <div></div>
          );
        },
        right: true,
        width: "125px",
      },
    ],
    [intl, handleUnassociation, handleEdition]
  );

  const editValidationBlur = (name) => {
    let errors = editFormRef.current.getErrors();
    validateSingleFieldOnBlur(errors, editSchema, name, editFormRef);
  };

  const handleEditionSubmit = useCallback(
    async (data) => {
      try {
        if (editModalIsOpen && editData) {
          editFormRef.current?.setErrors({});

          await editSchema.validate(data, { abortEarly: false });

          setLoading(true);
          if (loading) return;

          handleLoading(true);

          api
            .put(
              `/accreditation/${editData.id}`,
              {
                eventId: editData.eventId,
                opportunityId: data.edit_opportunity,
                associateCollaboratorId: data.edit_collaborator,
                categoryId: data.edit_category,
                credentialName: data.edit_credential_name,
                jobTitle: data.edit_assignment,
                email: data.edit_email,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "*/*",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((result) => {
              addToast({
                type: "success",
                title: intl.formatMessage({
                  id: "success",
                }),
                description: intl.formatMessage({
                  id: "success.credential_updated",
                }),
              });
              if(!searchCanBePreserved) clearFields()
              else { 
                handleSearchForAssociatedCredentials()
                handleUpdateCategoriesAndCollaborators()
              }
              setSearchCanBePreserved(true)
            })
            .catch((err) =>
              addToast({
                type: "error",
                title: intl.formatMessage({
                  id: "error",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              })
            )
            .finally(() => {
              setEditModalIsOpen(false);
              setLoading(false);
              handleLoading(false);
            });
        }
      } catch (err) {
        setLoading(false);
        handleLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          editFormRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [
      editSchema,
      loading,
      handleLoading,
      token,
      addToast,
      intl,
      editModalIsOpen,
      editData,
    ]
  );

  useEffect(()=>{
    const params = new URLSearchParams(document.location.search);
    if(!events) return
    if(!params.get("event")) return
    formRef.current.setFieldValue('event', {
      label: events.filter(
        (i) => i.value === Number(params.get("event"))
      )[0]?.label,
      value: params.get("event")
    },)
  },[events])

  const buildReturnUrl = ()=>{
    const initialURL = `/credentials`
    const partialURLEvent = !formRef.current.getFieldValue('event') ? "" : `?event=${formRef.current.getFieldValue('event')}`

    return initialURL + partialURLEvent
  }


  const handleNewCollaboratorClick = () =>{
    history.replace(buildReturnUrl())
    history.push('/credentials/new')
  }

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.credentials" })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleAssociateSubmit}
            initialData={{
              is_printed: {
                label: intl.formatMessage({ id: "all" }),
                value: null,
              },
            }}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="manage_credentials" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                  <BadgeContainer
                    onClick={handleNewCollaboratorClick}
                  >
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({ id: "new_collaborator" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <SelectWithLabel
                name="event"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "event_edition" })}
                placeholder={intl.formatMessage({ id: "event_edition" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={events}
                onChange={(event) => setSelectedEvent(event?.value)}
                required
              />
              <SelectWithLabel
                name="contract"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "contract" })}
                placeholder={intl.formatMessage({ id: "contract" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={contracts}
                onChange={(contract) => setSelectedContract(contract?.value)}
                required
              />
              <SelectWithLabel
                name="category"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "credential_types" })}
                placeholder={intl.formatMessage({ id: "credential_types" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={types}
                onChange={(category) => setSelectedCategory(category?.value)}
                required
              />
            </FieldsContainer>
            {selectedAllFilters ? (
              <>
                <FieldsContainer>
                  <SelectWithLabel
                    name="collaborator"
                    closeMenuOnSelect={false}
                    placeholder={intl.formatMessage({
                      id: "collaborator",
                    })}
                    label={intl.formatMessage({
                      id: "collaborator",
                    })}
                    validationBlur={validationBlur}
                    labelOrientation="horizontal"
                    labelWidth="150px"
                    options={collaborators}
                    isMulti
                    required
                  />
                  <AssociateActionsContainer>
                    <AssociateContainer>
                      <FormButton type="submit" loading={loading} marginTop={'0px'}>
                        <FormattedMessage id="associate_selected_collaborators" />
                      </FormButton>
                    </AssociateContainer>
                  </AssociateActionsContainer>
                </FieldsContainer>
                <TitleContainer>
                  <Title>
                    <FormattedMessage id="search_for_associated_credentials" />
                  </Title>
                </TitleContainer>
                <FieldsContainer>
                  <Horizontal>
                    <InputWithLabel
                      name="name"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "name" })}
                      placeholder={intl.formatMessage({ id: "name" })}
                    />
                    <MaskedInputWithLabel
                      name="document"
                      maskChar={null}
                      mask={documentMask}
                      onChange={setDocumentMask}
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "document_cpf_or_passport" })}
                      placeholder={intl.formatMessage({
                        id: "document_filter",
                      })}
                    />
                  </Horizontal>
                  <Horizontal>
                    <SelectWithLabel
                      name="is_printed"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "printed_credential" })}
                      placeholder={intl.formatMessage({
                        id: "printed_credential",
                      })}
                      options={statusBasedValues}
                    />
                    <InputWithLabel
                      name="company_name"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({
                        id: "company_name_credential",
                      })}
                      placeholder={intl.formatMessage({
                        id: "company_name_credential",
                      })}
                    />
                  </Horizontal>
                  <FilterActionsContainer>
                    <FilterSubmitContainer>
                      <FormButton
                        type="button"
                        onClick={handleSearchForAssociatedCredentials}
                        loading={loading}
                      >
                        <FormattedMessage id="search" />
                      </FormButton>
                    </FilterSubmitContainer>
                  </FilterActionsContainer>
                </FieldsContainer>
              </>
            ) : null}
          </Form>
        </FormContainer>
      </Container>

      <CredentialsActionContainer>
        <BadgeContainer
          onClick={() => history.push("/credential/schedule-withdrawal")}
        >
          <Badge
            color="#33d27e"
            text={intl.formatMessage({ id: "schedule_withdrawal" })}
            fontSize="16px"
          />
        </BadgeContainer>
      </CredentialsActionContainer>

      {filtered && selectedAllFilters ? (
        <>
          <CredentialsActionContainer />
          <Table
            columns={columns}
            data={data}
            title={intl.formatMessage({ id: "results" })}
            progressPending={loading}
            progressComponent={
              <Loader type="Oval" color="#00BFFF" height={100} width={100} />
            }
          />
        </>
      ) : (
        <CredentialsActionContainer>
          <FormattedMessage id="need_to_filter_first" />
        </CredentialsActionContainer>
      )}
      <ConfirmationModal
        setIsOpen={setCollaboratorWithoutLastNameOrEmailModalOpen}
        modalIsOpen={collaboratorWithoutLastNameOrEmailModalOpen}
        confirmationFunction={() => downloadCollaboratorsWithoutLastNameOrEmailTxt()}
        description={intl.formatMessage({ id: "collaborators_without_last_name_email_modal" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <Modal
        setIsOpen={setEditModalIsOpen}
        modalIsOpen={editModalIsOpen}
        modalWidth="50%"
        overflowX="auto"
      >
        <FormContainer>
          <Form
            ref={editFormRef}
            onSubmit={handleEditionSubmit}
            key={key}
            initialData={{
              edit_opportunity: editOpportunityItem,
              edit_collaborator: editCollaboratorItem,
              edit_category: editCategoryItem,
              edit_assignment: editData?.associateCollaboratorJobTitle,
              edit_credential_name: editData?.credentialName,
              edit_email: editData?.associateCollaboratorEmail,
            }}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="edit_association" />
              </Title>
            </TitleContainer>
            <FieldsContainer>
              <AdditionalInformationContainer>
                <InformationContainer>
                  <InformationLabel>
                    <FormattedMessage id="event_edition" />
                  </InformationLabel>
                  <InformationValue>
                    {editData?.eventName || "-"}
                  </InformationValue>
                </InformationContainer>
                <InformationContainer>
                  <InformationLabel>
                    <FormattedMessage id="type" />
                  </InformationLabel>
                  <InformationValue>
                    {editData?.userRole === "EXHIBITOR"
                      ? intl.formatMessage({ id: "exhibitor" })
                      : intl.formatMessage({ id: "service_provider" })}
                  </InformationValue>
                </InformationContainer>
                <InformationContainer>
                  <InformationLabel>
                    <FormattedMessage id="account" />
                  </InformationLabel>
                  <InformationValue>
                    {editData?.userName || "-"}
                  </InformationValue>
                </InformationContainer>
              </AdditionalInformationContainer>
              <SelectWithLabel
                name="edit_opportunity"
                placeholder={intl.formatMessage({
                  id: "contract",
                })}
                label={intl.formatMessage({
                  id: "contract",
                })}
                validationBlur={editValidationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={editOpportunities}
                onChange={(opportunity) => {
                  setEditSelectedOpportunity(opportunity?.value)
                  setSearchCanBePreserved(false)
                }}
                required
              />
              <SelectWithLabel
                name="edit_category"
                validationBlur={editValidationBlur}
                label={intl.formatMessage({ id: "credential_types" })}
                placeholder={intl.formatMessage({ id: "credential_types" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={editCategories}
                onChange = {()=> setSearchCanBePreserved(false)}
                required
              />
              <SelectWithLabel
                name="edit_collaborator"
                placeholder={intl.formatMessage({
                  id: "collaborator",
                })}
                label={intl.formatMessage({
                  id: "collaborator",
                })}
                validationBlur={editValidationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={editCollaborators}
                onChange={(collaborator) =>
                  setEditSelectedCollaborator(collaborator)
                }
                required
              />
              <InputWithHorizontalLabel
                name="edit_assignment"
                placeholder={intl.formatMessage({
                  id: "assignment",
                })}
                label={intl.formatMessage({
                  id: "assignment",
                })}
                validationBlur={editValidationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="edit_credential_name"
                placeholder={intl.formatMessage({
                  id: "credential_name",
                })}
                label={intl.formatMessage({
                  id: "credential_name",
                })}
                validationBlur={editValidationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="edit_email"
                placeholder={intl.formatMessage({
                  id: "email",
                })}
                label={intl.formatMessage({
                  id: "email",
                })}
                validationBlur={editValidationBlur}
                labelWidth="150px"
                required
              />
              <Horizontal>
                <FilterEditionContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={() => setEditModalIsOpen(false)}
                      loading={isLoading}
                    >
                      <FormattedMessage id="close" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="save" />
                    </FormButton>
                  </SubmitContainer>
                </FilterEditionContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Modal>
    </AnimatedMainContainer>
  );
};

export default Credentials;
