import { all, takeLatest, call, put } from "redux-saga/effects";

import { 
  setNewEmployeeCategoriesSuccess,
  setCollaboratorsSuccess,
  setSingleCollaboratorSuccess
} from "./actions";
import { 
  getNewEmployeeCategorieServiceQuota, 
  postNewCollaboratorService,
  getCollaboratorsService,
  getSingleCollaboratorService,
  editCollaboratorService,
  deleteCollaboratorService
} from '../../../service/employee.service'

function* getNewEmployeeCategories({payload}) {
  const { selectedEvent, selectedContract, callbackError, isPortuguese } = payload
  
  try {
    const response = yield call(getNewEmployeeCategorieServiceQuota, 
      {
        event_id: selectedEvent,
        opportunity_id: selectedContract,
      } )
    
    const credentialCategories = response.data.credentialCategoryOutputList.map((category)=>{
      return (
        {
          value: category.id,
          label: isPortuguese ? category.title : category.englishTitle,
          information: {
            total: category.total || 0,
            used: category.used || 0,
          },
        }
      )
    })

    yield put(setNewEmployeeCategoriesSuccess(credentialCategories))

  } catch (error) {
    callbackError(error.response.data.code);
  }
}

function* postNewEmployee({payload}){
  const {employee, callBackError, callBackSuccess} = payload
  try{
    const response = yield call(postNewCollaboratorService, employee)
    if (response.status === 200) callBackSuccess(response.data.code, employee.document)
   } catch (error) {
    callBackError(error.response.data.code);
  }
}

function* editEmployee({payload}){
  const {employee, callBackError, callBackSuccess, id} = payload
  try{
    const response = yield call(editCollaboratorService, id, employee)
    if (response.status === 200) callBackSuccess(response.data.code)
   } catch (error) {
    callBackError(error.response.data.code);
  }
}

function* deleteEmployee({payload}){
  const {callBackError, callBackSuccess, id} = payload
  try{
    const response = yield call(deleteCollaboratorService, id)
    if (response.status === 200) callBackSuccess(response.data.code)
   } catch (error) {
    callBackError(error.response.data.code);
  }
}

function* getCollaborators({payload}){
  const {page, perPage, search, callBackError} = payload
  try {
    const response = yield call(getCollaboratorsService, page, perPage, search)
    if (response.data.code === 10003) yield put(setCollaboratorsSuccess(response.data))
  } catch(error) {
    callBackError(error.response.data.code);
  }
}

function* getSingleCollaborator({payload}){
  const {id, callBackError, callBackSuccess} = payload
  try {
    const response = yield call(getSingleCollaboratorService, id)
    if (response.data.code === 10002){ 
      yield put(setSingleCollaboratorSuccess(response.data.associateCollaboratorOutput))
      callBackSuccess(response.data.associateCollaboratorOutput)
    }
  } catch(error) {
    callBackError(error.response.data.code);
  }
}

export default all([
  takeLatest("GET_NEW_EMPLOYEE_CATEGORIES", getNewEmployeeCategories),
  takeLatest("POST_NEW_EMPLOYEE", postNewEmployee),
  takeLatest("EDIT_EMPLOYEE", editEmployee),
  takeLatest("DELETE_EMPLOYEE", deleteEmployee),
  takeLatest("GET_COLLABORATORS", getCollaborators),
  takeLatest("GET_SINGLE_COLLABORATOR", getSingleCollaborator),
]);