import React, { useEffect, useRef, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { base64ToBlob, downloadBlob } from "../../utils/file";
import { format, parseISO } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  LinkButton,
  NotificationContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Badge from "../../components/Badge";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import Table from "../../components/Table";
import Report from "./report";
import { cnpjMask } from "../../utils/mask";
import MaskedInputWithLabel from "../../components/MaskedInputWithLabel";
import InputWithLabel from "../../components/InputWithLabel";
import swapcardReturnTypesConstants from "../../constants/swapcardReturnTypes";

const ManageSwapcardIntegrationReport = () => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState();
  const [data, setData] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [accounts, setAccounts] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const swapcardReturnTypes = swapcardReturnTypesConstants();

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    company_name: Yup.string(),
    document: Yup.string(),
    email: Yup.string(),
    has_error: Yup.bool().nullable(),
    profile_type: Yup.string().nullable(),
    action_type: Yup.string().nullable(),
  });

  const hasErrorOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "both" }),
        value: null,
      },
      {
        label: intl.formatMessage({ id: "yes" }),
        value: true,
      },
      {
        label: intl.formatMessage({ id: "no" }),
        value: false,
      },
    ],
    [intl]
  );

  const profileTypeOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "both" }),
        value: null,
      },
      {
        label: intl.formatMessage({ id: "member" }),
        value: "MEMBER",
      },
      {
        label: intl.formatMessage({ id: "company" }),
        value: "COMPANY",
      },
    ],
    [intl]
  );

  const actionTypeOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "both" }),
        value: null,
      },
      {
        label: intl.formatMessage({ id: "add_type" }),
        value: "Add",
      },
      {
        label: intl.formatMessage({ id: "update" }),
        value: "Update",
      },
    ],
    [intl]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .patch(
            `/admin/digital-platform-report`,
            {
              eventId: data.event,
              companyName: data.company_name,
              document: data.document,
              email: data.email,
              hasError: data.has_error,
              profileType: data.profile_type,
              actionType: data.action_type,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.output);
            setShowExport(true);
          })
          .catch((err) => {
            setData([]);
            setShowExport(false);
          })
          .finally(() => {
            setLoading(false);
            setFiltered(true);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [loading, schema, token]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.idNumber,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );

        setEvents(eventsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", "");
    formRef.current.setFieldValue("has_error", {
      label: intl.formatMessage({ id: "both" }),
      value: null,
    });
    formRef.current.setFieldValue("profile_type", {
      label: intl.formatMessage({ id: "both" }),
      value: null,
    });
    formRef.current.setFieldValue("action_type", {
      label: intl.formatMessage({ id: "both" }),
      value: null,
    });
    setShowExport(false);
    setData([]);
    setFiltered(false);
  };

  const onChangeEvent = (event) => {
    setFilename(event?.label?.toUpperCase());
    setSelectedEvent(event?.value);
    setShowExport(false);
    setData([]);
    setFiltered(false);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "bar_code" }),
        selector: (row) => row.credentialBarcode,
        sortable: false,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
      },
      {
        name: intl.formatMessage({ id: "profile_type" }),
        selector: (row) => row.profileType,
        cell: (row) =>
          row.profileType === "MEMBER"
            ? intl.formatMessage({ id: "member" })
            : intl.formatMessage({ id: "company" }),
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "140px",
      },
      {
        name: intl.formatMessage({ id: "action_type" }),
        selector: (row) => row.actionType,
        cell: (row) =>
          row.actionType === "Add"
            ? intl.formatMessage({ id: "add_type" })
            : intl.formatMessage({ id: "update" }),
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "140px",
      },
      {
        name: intl.formatMessage({ id: "status_returned" }),
        selector: (row) => row.statusReturned,
        cell: (row) =>
          swapcardReturnTypes.filter((pt) => pt.value === row.statusReturned)[0]?.label,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "has_error" }),
        selector: (row) => row.error,
        cell: (row) =>
          row.error
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
      },
      {
        name: intl.formatMessage({ id: "date" }),
        selector: (row) => row.createdAt,
        sortable: true,
        format: (row) =>
          format(parseISO(row.createdAt), "dd/MM/yyyy HH:mm"),
        allowOverflow: true,
        wrap: false,
        minWidth: "115px",
      },
      {
        name: intl.formatMessage({ id: "event_edition" }),
        selector: (row) => row.eventEditionName,
        sortable: false,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "contract_number" }),
        selector: (row) => row.contractNumber,
        sortable: false,
        allowOverflow: true,
        wrap: true,
        minWidth: "120px",
      },
      {
        name: intl.formatMessage({ id: "company_name" }),
        selector: (row) => row.companyName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "exhibitor_cnpj" }),
        selector: (row) => row.userDocument,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "170px",
      },
      {
        name: intl.formatMessage({ id: "exhibitor_mail" }),
        selector: (row) => row.userEmail,
        sortable: false,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "collaborator_mail" }),
        selector: (row) => row.collaboratorEmail,
        sortable: false,
        allowOverflow: true,
        wrap: true,
        minWidth: "200px",
      },
    ],
    [intl, swapcardReturnTypes]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.swapcard_integration_report" })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              has_error: {
                label: intl.formatMessage({ id: "both" }),
                value: null,
              },
              profile_type: {
                label: intl.formatMessage({ id: "both" }),
                value: null,
              },
              action_type: {
                label: intl.formatMessage({ id: "both" }),
                value: null,
              },
            }}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="export_swapcard_integration_report" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer>
                    <LinkButton onClick={() => history.push("/dashboard")}>
                      <Badge
                        color="#002244"
                        text={intl.formatMessage({ id: "goback" })}
                        fontSize="12px"
                      />
                    </LinkButton>
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  onChange={onChangeEvent}
                  options={events}
                  required
                />
              </Horizontal>
              <Horizontal>
                <InputWithLabel
                  name="company_name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "company_name" })}
                  placeholder={intl.formatMessage({ id: "company_name" })}
                  labelOrientation="vertical"
                />
                <MaskedInputWithLabel
                  name="document"
                  mask={cnpjMask}
                  validationBlur={validationBlur}
                  placeholder={intl.formatMessage({
                    id: "document_filter",
                  })}
                  label={intl.formatMessage({ id: "document_cnpj" })}
                />
              </Horizontal>
              <Horizontal>
                <InputWithLabel
                  name="email"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "company_email" })}
                  placeholder={intl.formatMessage({ id: "company_email" })}
                  labelOrientation="vertical"
                />
                <SelectWithLabel
                  name="has_error"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "has_error" })}
                  placeholder={intl.formatMessage({ id: "has_error" })}
                  labelOrientation="vertical"
                  options={hasErrorOptions}
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="profile_type"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "profile_type" })}
                  placeholder={intl.formatMessage({ id: "profile_type" })}
                  labelOrientation="vertical"
                  options={profileTypeOptions}
                />
                <SelectWithLabel
                  name="action_type"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "action_type" })}
                  placeholder={intl.formatMessage({ id: "action_type" })}
                  labelOrientation="vertical"
                  options={actionTypeOptions}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                  {showExport ? (
                    <SubmitContainer>
                      <FormButton type="button" loading={loading}>
                        <Report
                          filename={`${filename}_DIGITAL_PLATFORM_REQUEST_RETURN_REPORT`}
                          data={data}
                        >
                          <FormattedMessage id="export" />
                        </Report>
                      </FormButton>
                    </SubmitContainer>
                  ) : null}
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
          hasPagination
        />
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  );
};

export default ManageSwapcardIntegrationReport;
