import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo
} from "react";
import { useHistory } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
} from "./styles";

import CpfCnpj from "../../../../components/CpfCnpj";

import { useToast } from "../../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../../utils/formValidations";
import api from "../../../../service";
import AnimatedMainContainer from "../../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../../components/InputWithHorizontalLabel";
import MaskedInputWithHorizontalLabel from "../../../../components/MaskedInputWithHorizontalLabel";
import SelectWithLabel from "../../../../components/SelectWithLabel";
import Badge from "../../../../components/Badge";
import { useLoading } from "../../../../hooks/loading";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import Toggle from "../../../../components/Toggle";
import axios from "axios";
import
  TelephoneInputWithLabel,
  { getFullTelephoneNumber }
from "../../../../components/TelephoneInputWithLabel";

const AdminAssociateCollaboratorsCreate = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [personType, setPersonType] = useState("F");
  const [documentLabel, setDocumentLabel] = useState(
    intl.formatMessage({ id: "document_cpf" })
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFastTrack, setIsFastTrack] = useState(false);
  const [isCreateCredential, setIsCreateCredential] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [opportunities, setOpportunities] = useState([]);
  const [selectedOpportunity, setSelectedOpportunity] = useState();
  const [credentialTypes, setCredentialTypes] = useState([]);
  const [selectedRoleType, setSelectedRoleType] = useState();
  const isPortuguese = useMemo(()=> intl.locale === 'pt', [intl]);

  const history = useHistory();

  const roleTypes = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "exhibitor" }),
        value: "EXHIBITOR",
      },
      {
        label: intl.formatMessage({ id: "sponsor_without_m2" }),
        value: "SPONSOR_WITHOUT_M2",
      },
      {
        label: intl.formatMessage({ id: "service_provider" }),
        value: "SERVICE_PROVIDER",
      },
    ],
    [intl]
  );

  const schema = Yup.object().shape({
    roleType: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    user: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    nationality: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    last_name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    document: Yup.string(),
    assignment: Yup.string()
      .max(50, intl.formatMessage({ id: "error.max_length" }, { length: 50 }))
      .required(intl.formatMessage({ id: "error.field_required" })),
    credential_name: Yup.string()
      .max(23, intl.formatMessage({ id: "error.max_length" }, { length: 23 }))
      .required(intl.formatMessage({ id: "error.field_required" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "error.valid_email" }))
      .nullable()
      .when("fast_track", {
        is: (fast_track) => !fast_track,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: "error.field_required",
            })
          ),
      }),
    address: Yup.string(),
    telephone: Yup.string()
      .max(30, intl.formatMessage({ id: "error.max_length" }, { length: 30 })),
    event: Yup.string()
      .nullable()
      .when("create_credential", {
        is: (create_credential) => create_credential,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: "error.field_required",
            })
          ),
      }),
    opportunity: Yup.string()
      .nullable()
      .when("create_credential", {
        is: (create_credential) => create_credential,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: "error.field_required",
            })
          ),
      }),
    category: Yup.string()
      .nullable()
      .when("create_credential", {
        is: (create_credential) => create_credential,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: "error.field_required",
            })
          ),
      }),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        api
          .post(
            `/associate-collaborators/admin`,
            {
              fastTrack: isFastTrack,
              userId: data.user,
              international: data.nationality,
              name: data.name,
              lastName: data.last_name,
              credentialName: data.credential_name,
              document: data.document,
              jobTitle: data.assignment,
              email: data.email,
              address: data.address,
              telephone: getFullTelephoneNumber(
                data.telephone, data.telephone_selected_country),
              createCredential: data.create_credential,
              eventId: data.event,
              opportunityId: data.opportunity,
              categoryId: data.category,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success." + result.data.code,
              }),
            });
            setIsModalOpen(true);
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, schema, token]
  );

  useEffect(() => {
    formRef.current.setFieldValue("user");
    if (selectedRoleType) {
      handleLoading(true);
      api
        .get(`/users/active-clients-by-type`, {
          params: {
            role_type: selectedRoleType,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const users = result.data.users.map((user) => ({
            label: user.commercialName,
            value: user.id,
          }));
          setUsers(users);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  }, [selectedRoleType, addToast, intl, token, handleLoading]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleTypeChanges = (value) => {
    formRef.current.setFieldValue("document", "");
    switch (value) {
      case false:
        setPersonType("F");
        setDocumentLabel(intl.formatMessage({ id: "document_cpf" }));
        break;
      case true:
        setPersonType("I");
        setDocumentLabel(
          intl.formatMessage({ id: "document_passport" })
        );
        break;
      default:
        setDocumentLabel(intl.formatMessage({ id: "document" }));
        break;
    }
  };

  const resetForm = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("nationality", {
      label: intl.formatMessage({ id: "national" }),
      value: false,
    });
    formRef.current.setFieldValue("create_credential", false);
    formRef.current.setFieldValue("telephone_selected_country", {
      value: "BR"
    });
    setIsCreateCredential(false)
    setIsModalOpen(false);
  };

  const handleRoleTypeChange = (type) => {
    setSelectedRoleType(type?.value);
    if (type?.value !== "SERVICE_PROVIDER") {
      setIsFastTrack(false);
    }
  };

  useEffect(() => {
    formRef.current.setFieldValue("event");
    if (isCreateCredential && selectedUser) {
      handleLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      api
        .get(`/admin/events/by-user/${selectedUser}`, config)
        .then((eventResponse) => {
          setEvents(eventResponse.data.output);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    addToast,
    handleLoading,
    isCreateCredential,
    selectedUser,
    intl,
    token,
  ]);

  useEffect(() => {
    formRef.current.setFieldValue("opportunity");
    if (selectedEvent && selectedUser) {
      handleLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .patch(
          `/exhibitor/opportunity/admin-accreditation/event`,
          { eventId: selectedEvent, userId: selectedUser },
          config
        )
        .then((result) =>
          setOpportunities(
            [...result.data.output].sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            )
          )
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    addToast,
    intl,
    selectedEvent,
    selectedUser,
    token,
    handleLoading,
  ]);

  useEffect(() => {
    formRef.current.setFieldValue("category");
    if (selectedEvent && selectedOpportunity && selectedUser) {
      handleLoading(true);

      const config = {
        params: {
          event_id: selectedEvent,
          opportunity_id: selectedOpportunity,
          user_id: selectedUser,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      api
        .get(`/accreditation/quota`, config)
        .then((categoryResponse) => {
          const credentialCategories =
            categoryResponse.data.credentialCategoryOutputList.map((item) => ({
              label: isPortuguese ? item.title : item.englishTitle,
              value: item.id,
            }));
          setCredentialTypes(credentialCategories);
        })
        .catch((error) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + error.response.data.code,
            }),
          })
        )
        .finally(handleLoading(false));
    }
  }, [
    addToast,
    intl,
    selectedEvent,
    selectedOpportunity,
    selectedUser,
    token,
    handleLoading,
  ]);

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            nationality: {
              label: intl.formatMessage({ id: "national" }),
              value: false,
            },
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="new_collaborator" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() => history.go(-2)}
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <SelectWithLabel
                name="roleType"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "type" })}
                placeholder={intl.formatMessage({ id: "type" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={roleTypes}
                onChange={handleRoleTypeChange}
                required
              />
              <SelectWithLabel
                name="user"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "company" })}
                placeholder={intl.formatMessage({ id: "company" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={users}
                onChange={(user) => setSelectedUser(user?.value)}
                required
              />
              {selectedRoleType === "SERVICE_PROVIDER" && (
                <Toggle
                  name="fast_track"
                  label={intl.formatMessage({
                    id: "fast_track",
                  })}
                  onClick={() => setIsFastTrack(!isFastTrack)}
                />
              )}
              <SelectWithLabel
                name="nationality"
                label={intl.formatMessage({ id: "nationality" })}
                placeholder={intl.formatMessage({ id: "nationality" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={[
                  {
                    label: intl.formatMessage({ id: "national" }),
                    value: false,
                  },
                  {
                    label: intl.formatMessage({ id: "international" }),
                    value: true,
                  },
                ]}
                onChange={(e) => handleTypeChanges(e.value)}
                required
              />
              <InputWithHorizontalLabel
                name="name"
                placeholder={intl.formatMessage({
                  id: "name",
                })}
                label={intl.formatMessage({
                  id: "name",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="last_name"
                placeholder={intl.formatMessage({
                  id: "last_name",
                })}
                label={intl.formatMessage({
                  id: "last_name",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="credential_name"
                placeholder={intl.formatMessage({
                  id: "credential_name",
                })}
                label={intl.formatMessage({
                  id: "credential_name",
                })}
                validationBlur={validationBlur}
                maxLength="23"
                labelWidth="150px"
                required
              />
              <CpfCnpj
                name="document"
                placeholder={documentLabel}
                label={documentLabel}
                hasLabel
                labelOrientation="horizontal"
                personType={personType}
                validationBlur={validationBlur}
                labelWidth="150px"
              />
              <InputWithHorizontalLabel
                name="assignment"
                placeholder={intl.formatMessage({
                  id: "assignment",
                })}
                label={intl.formatMessage({
                  id: "assignment",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                maxLength="50"
                required
              />
              <InputWithHorizontalLabel
                name="email"
                placeholder="E-mail"
                label="E-mail"
                validationBlur={validationBlur}
                labelWidth="150px"
                required={!isFastTrack}
              />
              <InputWithHorizontalLabel
                name="address"
                placeholder={intl.formatMessage({
                  id: "address",
                })}
                label={intl.formatMessage({
                  id: "address",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
              />
              <TelephoneInputWithLabel
                name="telephone"
                placeholder={intl.formatMessage({
                  id: "telephone",
                })}
                label={intl.formatMessage({
                  id: "telephone",
                })}
                validationBlur={validationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
              />
              <Toggle
                name="create_credential"
                label={intl.formatMessage({
                  id: "create_credential_collaborator_page",
                })}
                onClick={() => setIsCreateCredential(!isCreateCredential)}
              />
            </FieldsContainer>
            {isCreateCredential ? (
              <>
                <FieldsContainer>
                  <SelectWithLabel
                    name="event"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "event_edition" })}
                    placeholder={intl.formatMessage({ id: "event_edition" })}
                    labelOrientation="horizontal"
                    labelWidth="150px"
                    options={events}
                    onChange={(event) => setSelectedEvent(event?.value)}
                    required
                  />
                  <SelectWithLabel
                    name="opportunity"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "contract" })}
                    placeholder={intl.formatMessage({ id: "contract" })}
                    labelOrientation="horizontal"
                    labelWidth="150px"
                    options={opportunities}
                    onChange={(opportunity) =>
                      setSelectedOpportunity(opportunity?.value)
                    }
                    required
                  />
                  <SelectWithLabel
                    name="category"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "credential_types" })}
                    placeholder={intl.formatMessage({ id: "credential_types" })}
                    labelOrientation="horizontal"
                    labelWidth="150px"
                    options={credentialTypes}
                    required
                  />
                </FieldsContainer>
              </>
            ) : null}
          </Session>
        </Form>
      </Container>
      <ConfirmationModal
        setIsOpen={setIsModalOpen}
        modalIsOpen={isModalOpen}
        confirmationFunction={resetForm}
        cancelationFunction={() =>
          history.go(-2)
        }
        description={intl.formatMessage({
          id: "new_collaborator_confirmation",
        })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
    </AnimatedMainContainer>
  );
};

export default AdminAssociateCollaboratorsCreate;
