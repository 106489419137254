import React, { useMemo } from "react";

import { useIntl } from "react-intl";

import Table from "../../../newUXComponents/Table";

import { convertToDateOnly } from "../../../utils/date";

import { Container } from "./styles";

const EcommerceProductsServices = ({ data = [] }) => {
  const intl = useIntl();

  const isPortuguese = useMemo(() => intl.locale === "pt", [intl]);

  const dataSource = useMemo(() => {
    if (data.length > 0) {
      return data.map((item) => ({ ...item, key: item.orderId }));
    }
  }, [data]);

  const columnsProducts = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: "order_number" }),
        dataIndex: "orderId",
        key: "orderId",
      },
      {
        title: intl.formatMessage({ id: "situation" }),
        dataIndex: "statusDesc",
        key: "statusDesc",
        render: (_, record) => {
          if (record.paid) {
            return intl.formatMessage(
              { id: "paid_on" },
              { value: convertToDateOnly(record.paymentDate) }
            );
          }
          return record.statusDesc;
        },
      },
      {
        title: intl.formatMessage({ id: "sale_date" }),
        dataIndex: "saleDate",
        key: "saleDate",
        render: (text) => {
          if (text) {
            return convertToDateOnly(text);
          }
          return " - ";
        },
      },
      {
        title: intl.formatMessage({ id: "value" }),
        dataIndex: "value",
        key: "value",
        render: (text, record) => {
          if (text) {
            return `${record.value.toFixed(2)} ${record.currency || ""}`;
          }
          return `0.00 ${record.currency || ""}` ;
        },
      },
      {
        title: intl.formatMessage({ id: "type" }),
        dataIndex: "roleType",
        key: "roleType",
        render: (text) => intl.formatMessage({ id: text }),
      },
      {
        title: intl.formatMessage({ id: "name" }),
        dataIndex: "clientName",
        key: "clientName",
      },
    ],
    [intl]
  );

  const columns = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: "item" }),
        dataIndex: "itemNumber",
        key: "itemNumber",
      },
      {
        title: intl.formatMessage({ id: "description" }),
        dataIndex: "desc",
        key: "desc",
        render: (_, record) => {
          if (isPortuguese) {
            return record.description;
          }
          return record.descriptionEN;
        },
      },
      {
        title: intl.formatMessage({ id: "quantity" }),
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: intl.formatMessage({ id: "value" }),
        dataIndex: "unitValue",
        key: "unitValue",
        render: (text, record) => {
          if (text) {
            return `${record.unitValue.toFixed(2)} ${record.currency || ""}`;
          }
          return `0.00 ${record.currency || ""}`;
        },
      },
    ],
    [intl, isPortuguese]
  );

  const expandedRowRender = (record) => {
    const newRecord = record?.chlebaItemOutputs ?  record.chlebaItemOutputs.map((singleProduct)=> ({
      ...singleProduct,
      currency: record.currency
    })) : []
    return (
      <Table
        columns={columns}
        dataSource={newRecord}
        haveExpandable={false}
        disabledPagination
        isDisabledSeach
        notEven
      />
    );
  };

  return (
    <Container>
      <Table
        title={intl.formatMessage({
          id: "products_and_services_purchased_ecommerce",
        })}
        dataSource={dataSource}
        columns={columnsProducts}
        expandedRowRender={expandedRowRender}
        isDisabledSeach
        haveExpandable
        disabledPagination
      />
    </Container>
  );
};

export default EcommerceProductsServices;
